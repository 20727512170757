<template>
  <div id="EmbeddedList">
    <div class="input-group input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text pl-3 pr-3" id="addon-wrapping">
          <font-awesome-icon icon="search" aria-hidden="true"/>
        </span>
      </div>
      <input v-focus @keydown.enter.prevent v-model="inputSearch" type="text" class="form-control" placeholder="Buscar" aria-label="Buscar">
      <div class="input-group-append">
        <button class="btn btn-primary pl-3 pr-3" v-on:click.stop.prevent="toggleCreateNewCofrade()">
          <span v-if="!toggledCreateCofrade">
            <font-awesome-icon icon="plus" aria-hidden="true"/>
            <span class="hidden-fourth"> Nuevo</span>
          </span>
          <span v-else>
            <font-awesome-icon icon="ban" aria-hidden="true"/>
            <span class="hidden-fourth"> Cancelar</span>
          </span>
        </button>
      </div>
    </div>
    <CofradeCreate v-if="toggledCreateCofrade" :pop="true"/>
    <table v-if="areCofradesLoaded" class="table table-sm table-hover mb-0 mt-2" id="Predictions">
      <thead class="bg-dark text-light">
        <tr>
          <th scope="col"></th>
          <th scope="col">Nombre y apellidos</th>
          <th scope="col" class="hidden-fourth">Año entrada</th>
          <th scope="col">Pagado<span class="hidden-second"> hasta</span></th>
          <th scope="col" class="hidden-third">Notas</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cofrade in this.filteredCofrades" style="height: 38px;" :class="isConjoint(cofrade.cofrade_id) ? 'table-success' : ''" :key="cofrade.cofrade_id">
          <td class="fit" role="group">
            <button v-if="!isConjoint(cofrade.cofrade_id)" v-on:click.stop.prevent="appendConjoint(cofrade)" class="btn btn-sm btn-success">
              <font-awesome-icon icon="plus" aria-hidden="true"/>
            </button>
          </td>
          <td>
            <span style="cursor: pointer" v-on:click.stop.prevent="appendConjoint(cofrade)">
              <b>{{cofrade.full_name_switched}}</b>
              <span v-if="cofrade.nickname"> ({{cofrade.nickname}})</span>
              <span v-if="cofrade.deceased"> ✟</span>
            </span>
          </td>
          <td class="hidden-fourth full-content">{{year(cofrade.entry_date) || 'No registrado'}}</td>
          <td class="full-content"><b>{{payedTill(cofrade)}}</b></td>
          <td class="hidden-third">{{cofrade.notes || "-"}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { PaymentCalculator, TimeFormatter } from '@/helpers'

import CofradeCreate from '@/components/cofrade/CofradeCreate';

export default {
  name: 'CofradeMultiPayEmbeddedList',
  components: {
    CofradeCreate,
  },
  data() {
    return {
      inputSearch: '',
      toggledCreateCofrade: false,
      initialPredictedConjoints: null,
    }
  },
  computed: {
    ...mapGetters({
      cofrades: 'cofrade/getAllCofrades',
      areCofradesLoaded: 'cofrade/areLoaded',
      predictedConjoints: 'cofrade/getPredictedConjoints',
      arePredictedConjointsLoaded: 'cofrade/arePredictedConjointsLoaded',
      isConjoint: 'cofrade/isConjoint',
    }),
    filteredCofrades() {
      function generalize(str) {
        return str
          .replace(/á/g, "a")
          .replace(/é/g, "e")
          .replace(/í/g, "i")
          .replace(/ó/g, "o")
          .replace(/ú/g, "u")
          .replace(/ü/g, "u")
          .replace(/Á/g, "a")
          .replace(/É/g, "e")
          .replace(/Í/g, "i")
          .replace(/Ó/g, "o")
          .replace(/Ú/g, "u")
          .replace(/Ü/g, "u")
          .replace(/,/g, "");
      }
      if (this.inputSearch == '' && this.initialPredictedConjoints)
        return this.initialPredictedConjoints;

      return this.cofrades.filter(cofrade => {
        return (
          generalize((cofrade.nickname || '').toLowerCase()).includes(generalize(this.inputSearch.toLowerCase())) ||
          generalize((cofrade.full_name || '').toLowerCase()).includes(generalize(this.inputSearch.toLowerCase())) ||
          generalize((cofrade.full_name_switched || '').toLowerCase()).includes(generalize(this.inputSearch.toLowerCase()))
        );
      }).splice(0,12);
    }
  },
  watch: {
    predictedConjoints: {
      async handler(newArray, oldArray) {
        if (!Array.isArray(oldArray) || newArray.length > oldArray.length) this.initialPredictedConjoints = newArray;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAllCofrades: 'cofrade/fetchAllCofrades',
      fetchCurrentCofradePredictedConjoints: 'cofrade/fetchCurrentCofradePredictedConjoints',
      cleanCurrentCofradePredictedConjointsMap: 'cofrade/cleanCurrentCofradePredictedConjointsMap',
      appendConjoint: 'cofrade/appendConjoint',
    }),
    payedTill: PaymentCalculator.payedTill,
    year: TimeFormatter.year,
    toggleCreateNewCofrade() {
      this.toggledCreateCofrade = !this.toggledCreateCofrade;
    }
  },
  created() {
    if (!this.areCofradesLoaded) this.fetchAllCofrades();
    if (!this.arePredictedConjointsLoaded) this.fetchCurrentCofradePredictedConjoints();
  },
  destroyed() {
    this.cleanCurrentCofradePredictedConjointsMap();
  }
}
</script>