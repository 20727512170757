import Vue from 'vue'

export default {
  SET_YEAR_PAYMENTS_MAP(state, yearPaymentsMap) {
    state.year_payments_map = yearPaymentsMap
    if (yearPaymentsMap !== null)
      Vue.$toast.info("Pagos anuales cargados!");
  },
  SET_COFRADE_PAYMENTS_MAP(state, cofradePaymentsMap) {
    state.cofrade_payments_map = cofradePaymentsMap
    if (cofradePaymentsMap !== null)
      Vue.$toast.info("Pagos cargados!");
  },
  SET_PAYMENT(state, payment) {
    if (
      state.year_payments_map &&
      Object.keys(state.year_payments_map).length !== 0 &&
      (new Date(Object.values(state.year_payments_map)[0].created_at)).getFullYear()
      ===
      (new Date(payment.created_at)).getFullYear()
    ) {
      Vue.set(state.year_payments_map, payment.payment_id, payment);
      Vue.$toast.info("Pago actualizado!");
    }
    if (
      state.cofrade_payments_map &&
      Object.keys(state.cofrade_payments_map).length !== 0 &&
      Object.values(state.cofrade_payments_map)[0].cofrade_id ==
        payment.cofrade_id
    ) {
      Vue.set(state.cofrade_payments_map, payment.payment_id, payment);
      Vue.$toast.info("Pago actualizado!");
    }
  },
  ADD_PAYMENT(state, payment) {
    if (
      state.year_payments_map && 
      Object.keys(state.year_payments_map).length !== 0 &&
      (new Date(Object.values(state.year_payments_map)[0].created_at)).getFullYear()
      ===
      (new Date(payment.created_at)).getFullYear()
    ) {
      Vue.set(state.year_payments_map, payment.payment_id, payment);
      Vue.$toast.info("Nuevo pago cargado!");
    }
    if (
      state.cofrade_payments_map &&
      Object.keys(state.cofrade_payments_map).length !== 0 &&
      Object.values(state.cofrade_payments_map)[0].cofrade_id ==
        payment.cofrade_id
    ) {
      Vue.set(state.cofrade_payments_map, payment.payment_id, payment);
      Vue.$toast.info("Nuevo pago cargado!");
    }
  },
  REMOVE_PAYMENT(state, payment_id) {
    if (state.year_payments_map && state.year_payments_map[payment_id]) {
      Vue.delete(state.year_payments_map, payment_id);
      Vue.$toast.info("Pago eliminado!");
    }
    if (state.cofrade_payments_map && state.cofrade_payments_map[payment_id]) {
      Vue.delete(state.cofrade_payments_map, payment_id);
      Vue.$toast.info("Pago eliminado!");
    }
  },
};
