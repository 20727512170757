<template>
  <div id="ModifyPayments">
    <form class="sticky-top form-inline justify-content-center bg-accent pt-2 pb-2">
      <font-awesome-icon icon="search" aria-hidden="true"/>
      <input @keydown.enter.prevent class="form-control form-control-sm ml-3 w-75" type="text" v-model="search" placeholder="Buscar" aria-label="Buscar">
    </form>
    <div v-if="areCofradesInitialized && areSharesLoaded" class="container-fluid mt-2">
      <div class="container-fluid form-inline justify-content-center pb-2">
        <jw-pagination :items="filteredCofrades" :pageSize="50" :maxPages="15" :labels="customLabels" @changePage="onChangePage"></jw-pagination>
      </div>
      <form @submit.prevent>
        <table class="sticky-top-2 table table-sm table-responsive-md table-hover">
          <thead class="bg-dark text-light">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col" class="hidden-third">Difunto</th>
              <th scope="col">Pagado</th>
              <th scope="col" class="hidden-first">Cuota</th>
              <th scope="col">Años</th>
              <th scope="col">Hasta</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cofrade in pageOfItems" :key="cofrade.cofrade_id">
              <td>
                <router-link tag="span" :to="{name: 'cofrade-profile', params: { cofrade_id: cofrade.cofrade_id }}">
                  <b style="cursor: pointer">{{cofrade.full_name_switched + (cofrade.nickname ? ` (${cofrade.nickname})` : '')}}</b>
                </router-link>
              <td class="hidden-third"><input type="checkbox" v-model="cofrade.deceased" v-bind:id="cofrade.cofrade_id"></td>
              <td class="full-content"><b>{{payedTill(cofrade)}}</b></td>
              <td class="hidden-first">
                <select v-model="cofrade.share_id" class="custom-select custom-select-sm">
                  <option v-for="share in getAllShares" :value="share.share_id" :key="share.share_id">{{share.name}}</option>
                </select>
                <div v-if="cofrade.paysEntry" class="form-check mt-1">
                  <input v-model="cofrade.paysEntry" class="form-check-input" type="checkbox" :id="`inputPaysEntry-${cofrade.cofrade_id}`" disabled>
                  <label class="form-check-label font-weight-bold text-warning" :for="`inputPaysEntry-${cofrade.cofrade_id}`">Paga entrada ({{getDefaultEntryShare.price - getShareById(cofrade.share_id).price}}€)</label>
                </div>
              </td>
              <td style="min-width:100px; max-width:130px !important">
                <ValidationProvider name="años a pagar" tag="div" rules="positive|required" v-slot="{ classes }">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend hidden-third">
                      <span v-on:click="cofrade.payingYears > 0 ? cofrade.payingYears-- : cofrade.payingYears = 0" class="btn btn-outline-secondary">
                        <font-awesome-icon icon="minus" aria-hidden="true"/>
                      </span>
                    </div>
                    <input v-model.number="cofrade.payingYears" type="text" class="form-control pr-1" :class="classes">
                    <div class="input-group-append hidden-fourth">
                      <span v-on:click.stop.prevent="cofrade.payingYears++" class="btn btn-outline-secondary" type="button">
                        <font-awesome-icon icon="plus" aria-hidden="true"/>
                      </span>
                    </div>
                  </div>
                </ValidationProvider>
              </td>
              <td>{{payingTill(cofrade)}}</td>
              <td role="group" class="fit">
                <div class="btn-group btn-block">
                  <button v-if="!isDeceasedModified(cofrade) && totalCofrade(cofrade) === 0" class="btn btn-sm btn-success text-uppercase" v-on:click.stop.prevent>
                    <span>Actual</span>
                  </button>
                  <button v-else class="btn btn-sm btn-danger" :disabled="cofrade.loading" v-on:click.stop.prevent="onSubmit(cofrade)">
                    <span v-if="cofrade.loading" class="spinner-border spinner-border-sm"></span>
                    <span v-else>Enviar</span>
                  </button>
                  <button v-on:click.stop.prevent="$router.push({name: 'cofrade-profile', params: { cofrade_id: cofrade.cofrade_id }})" class="btn btn-sm btn-primary">
                    <font-awesome-icon icon="indent" aria-hidden="true"/>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td class="hidden-first"></td>
              <td class="hidden-third"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <div class="btn-block">
                  <button v-if="!isAnyDeceasedModified() && total(cofrades) === 0" class="btn btn-success text-uppercase" v-on:click.stop.prevent>
                    <span>Actual</span>
                  </button>
                  <button v-else class="btn btn-danger text-uppercase" :disabled="loading" v-on:click.stop.prevent="onGlobalSubmit()">
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                    <span> Enviar</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
    <div v-else class="mt-2 text-center">
      <span class="spinner-border spinner-border-xl"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { shallowDiff } from '@/helpers';
import formValidationMixin from '@/mixins/formValidationMixin'
import momentMixin from '@/mixins/momentMixin'
import paginationMixin from '@/mixins/paginationMixin'
import { PaymentCalculator } from '@/helpers'
import CofradeService from '@/services/cofrade.service';
import PaymentService from '@/services/payment.service';

export default {
  name: 'ModifyPayments',
  mixins: [formValidationMixin, momentMixin, paginationMixin],
  data() {
    return {
      cofrades: null,
      areCofradesInitialized: false,
      loading: false,
      search: '',
      pageOfItems: [],
    }
  },
  computed: {
    ...mapGetters({
      getAllCofrades: 'cofrade/getAllCofrades',
      getCofradeById: 'cofrade/getCofradeById',
      areCofradesLoaded: 'cofrade/areLoaded',
      getAllShares: 'share/getAllShares',
      getDefaultEntryShare: 'share/getDefaultEntryShare',
      getDefaultOrdinaryShare: 'share/getDefaultOrdinaryShare',
      areSharesLoaded: 'share/areLoaded',
    }),
    filteredCofrades() {
      function generalize(str) {
        return str
          .replace(/á/g, "a")
          .replace(/é/g, "e")
          .replace(/í/g, "i")
          .replace(/ó/g, "o")
          .replace(/ú/g, "u")
          .replace(/ü/g, "u")
          .replace(/Á/g, "a")
          .replace(/É/g, "e")
          .replace(/Í/g, "i")
          .replace(/Ó/g, "o")
          .replace(/Ú/g, "u")
          .replace(/Ü/g, "u");
      }
      return this.cofrades.filter(cofrade => {
        return (
          generalize((cofrade.nickname || '').toLowerCase()).includes(generalize(this.search.toLowerCase())) ||
          generalize((cofrade.full_name || '').toLowerCase()).includes(generalize(this.search.toLowerCase())) ||
          generalize((cofrade.full_name_switched || '').toLowerCase()).includes(generalize(this.search.toLowerCase()))
        );
      });
    },
  },
  watch: {
    getAllCofrades: {
      async handler(newCofradesArr, oldCofradesArr) {
        if (this.cofrades === null) {
          this.cofrades = JSON.parse(JSON.stringify(newCofradesArr));
          for (var cofrade of this.cofrades) {
            this.$set(cofrade, 'share_id', this.getDefaultOrdinaryShare.share_id);
            this.$set(cofrade, 'paysEntry', !cofrade.payed_entry)
            this.$set(cofrade, 'payingYears', 0);
          }
          this.areCofradesInitialized = true;
        }
        else {
          var acc = 0;
          for (let index = 0; index < newCofradesArr.length; index++) {
            if (newCofradesArr[index].cofrade_id === (oldCofradesArr && oldCofradesArr[index-acc] && oldCofradesArr[index-acc].cofrade_id)) {
              Object.assign(this.cofrades[index], shallowDiff(newCofradesArr[index], oldCofradesArr[index-acc]));
            } else {
              acc++;
              this.cofrades.splice(index, 0, JSON.parse(JSON.stringify(newCofradesArr[index])));
              this.$set(this.cofrades[index], 'share_id', this.getDefaultOrdinaryShare.share_id);
              this.$set(this.cofrades[index], 'paysEntry', !this.cofrades[index].entry_date)
              this.$set(this.cofrades[index], 'payingYears', 0);
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAllCofrades: 'cofrade/fetchAllCofrades',
      fetchAllShares: 'share/fetchAllShares',
    }),
    payingTill: PaymentCalculator.payingTill,
    payedTill: PaymentCalculator.payedTill,
    totalCofrade: PaymentCalculator.totalCofrade,
    total: PaymentCalculator.total,
    isDeceasedModified(cofrade) {
      return cofrade.deceased != this.getCofradeById(cofrade.cofrade_id).deceased;
    },
    isAnyDeceasedModified() {
      return this.cofrades.some((cofrade) => this.isDeceasedModified(cofrade));
    },
    async onSubmit(cofrade) {
      this.$set(cofrade, 'loading', true);
      let entryPayment = null;
      let complementaryPayment = null;
      if (this.isDeceasedModified(cofrade)) {
        CofradeService.update({ cofrade_id: cofrade.cofrade_id, deceased: cofrade.deceased })
          .catch(error => this.$toast.error(error.message));
      }
      let cofradePayments;
      try {
        cofradePayments = Object.values((await CofradeService.getPaymentsById(cofrade.cofrade_id)).data);
      } catch (error) {
        this.$toast.error(error.message);
      }
      if (cofrade.paysEntry && cofrade.payingYears > 0) {
        entryPayment = {
          years: 1,
          share_id: this.getDefaultEntryShare.share_id,
          cofrade_id: cofrade.cofrade_id,
          payer_id: cofrade.cofrade_id,
        };
        if (Object.keys(cofradePayments).length > 0) {
          entryPayment.created_at = cofradePayments ? cofradePayments[cofradePayments.length - 1].created_at : new Date();
        } else {
          entryPayment.created_at = new Date();
        }
      }
      if (cofrade.payingYears > 1*cofrade.paysEntry) {
        complementaryPayment = {
          years: cofrade.payingYears - 1*cofrade.paysEntry,
          share_id: cofrade.share_id,
          cofrade_id: cofrade.cofrade_id,
          payer_id: cofrade.cofrade_id,
        }
        if (Object.keys(cofradePayments).length > 0) {
          complementaryPayment.created_at = cofradePayments ? cofradePayments[cofradePayments.length - 1].created_at : new Date();
        } else {
          complementaryPayment.created_at = new Date();
        }
      }
      if (entryPayment){
        await PaymentService.post(entryPayment)
          .then(() => {
            this.$set(cofrade, 'share_id', this.getDefaultOrdinaryShare.share_id);
            this.$set(cofrade, 'payingYears', 0);
          })
          .catch(error => this.$toast.error(error.message));
      }
      if (complementaryPayment){
        await PaymentService.post(complementaryPayment)
          .then(() => {
            this.$set(cofrade, 'share_id', this.getDefaultOrdinaryShare.share_id);
            this.$set(cofrade, 'payingYears', 0);
          })
          .catch(error => this.$toast.error(error.message));
      }
      this.$set(cofrade, 'loading', false);
    },
    async onGlobalSubmit() {
      for (let cofrade of this.cofrades) {
        this.loading = true;
        if (this.isDeceasedModified(cofrade) || this.totalCofrade(cofrade) !== 0) {
          await this.onSubmit(cofrade);
        }
        this.loading = false;
      }
    }
  },
  async created() {
    if (!this.areSharesLoaded) await this.fetchAllShares();
    if (!this.areCofradesLoaded) this.fetchAllCofrades();
    else {
      this.cofrades = JSON.parse(JSON.stringify(this.getAllCofrades));
      for (var cofrade of this.cofrades) {
        this.$set(cofrade, 'share_id', this.getDefaultOrdinaryShare.share_id);
        this.$set(cofrade, 'paysEntry', !cofrade.payed_entry)
        this.$set(cofrade, 'payingYears', 0);
      }
      this.areCofradesInitialized = true;
    }
  },
}
</script>