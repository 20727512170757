import store from "@/store";
import ShareService from '@/services/share.service';

const shareCreation = {
  eventType: "SHARE_CREATION",
  handle: function(event) {
    const share_id = JSON.parse(event.data).share_id;
    ShareService.getById(share_id)
      .then((share) => store.commit("share/ADD_SHARE", share.data))
      .catch(() => {});
  },
};

export default shareCreation;
