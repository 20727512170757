import state from "./bookState";
import mutations from "./bookMutations";
import getters from "./bookGetters";
import actions from "./bookActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
