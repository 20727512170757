import PaymentService from "@/services/payment.service";

export default {
  fetchYearPayments({ commit }, year) {
    return new Promise((resolve, reject) => {
      PaymentService.getByYear(year || (new Date).getFullYear())
        .then((payments) => {
          commit("SET_YEAR_PAYMENTS_MAP", payments.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error.response?.data?.status);
        })
    });
  },
  fetchCofradePayments({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      PaymentService.getByCofradeId(rootState.cofrade.current_cofrade_id)
        .then((payments) => {
          commit("SET_COFRADE_PAYMENTS_MAP", payments.data);
          resolve("success");
        })
        .catch((error) => {
          reject(error.response?.data?.status);
        });
    });
  },
  cleanPayments({ commit }) {
    commit("SET_YEAR_PAYMENTS_MAP", null);
    commit("SET_COFRADE_PAYMENTS_MAP", null);
  }
};
