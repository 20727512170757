import state from "./authState";
import mutations from "./authMutations";
import getters from "./authGetters";
import actions from "./authActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
