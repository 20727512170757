<template>
  <div id="ModifyPages">
    <form class="sticky-top form-inline justify-content-center bg-accent pt-2 pb-2">
      <font-awesome-icon icon="search" aria-hidden="true"/>
      <input @keydown.enter.prevent class="form-control form-control-sm ml-3 w-75" type="text" v-model="search" placeholder="Buscar" aria-label="Buscar">
    </form>
    <div v-if="areCofradesInitialized && areBooksLoaded" class="container-fluid mt-2">
      <form @submit.prevent>
        <table class="sticky-top-2 table table-sm table-responsive-md table-hover">
          <thead class="bg-dark text-light">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Entrada</th>
              <th scope="col">Libro</th>
              <th scope="col">Página</th>
              <th scope="col">Vuelto</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cofrade in pageOfItems" :key="cofrade.cofrade_id">
              <td>
                <router-link tag="span" :to="{name: 'cofrade-profile', params: { cofrade_id: cofrade.cofrade_id }}">
                  <b style="cursor: pointer">{{cofrade.full_name_switched + (cofrade.nickname ? ` (${cofrade.nickname})` : '')}}</b>
                </router-link>
              </td>
              <td>{{moment({format: 'year', date: cofrade.entry_date || null }) || 'No registrado'}}</td>
              <td>
                <select v-model="cofrade.book_id" class="custom-select custom-select-sm">
                  <option v-for="book in getAllBooks" :value="book.book_id" :key="book.book_id">{{`#${book.book_id} - ${book.title}`}}</option>
                </select>
              </td>
              <td>
                <ValidationProvider name="pagina" tag="div" rules="positive|required" v-slot="{ classes }">
                  <input v-model.number="cofrade.parsed_page_number" type="text" class="form-control form-control-sm pr-1" :class="classes">
                </ValidationProvider>
              </td>
              <td><input type="checkbox" v-model="cofrade.page_turned"></td>
              <td role="group" class="fit">
                <div class="btn-group btn-block">
                  <button v-if="!isModified(cofrade)" class="btn btn-sm btn-success text-uppercase" v-on:click.stop.prevent>
                    <span>Actual</span>
                  </button>
                  <button v-else class="btn btn-sm btn-danger" :disabled="cofrade.loading" v-on:click.stop.prevent="onSubmit(cofrade)">
                    <span v-if="cofrade.loading" class="spinner-border spinner-border-sm"></span>
                    <span v-else> Enviar</span>
                  </button>
                  <button v-on:click.stop.prevent="$router.push({name: 'cofrade-profile', params: { cofrade_id: cofrade.cofrade_id }})" class="btn btn-sm btn-primary">
                    <font-awesome-icon icon="indent" aria-hidden="true"/>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <div class="container-fluid form-inline justify-content-center">
        <jw-pagination :items="filteredCofrades" :pageSize="30" :maxPages="5" :labels="customLabels" @changePage="onChangePage"></jw-pagination>
      </div>
    </div>
    <div v-else class="mt-2 text-center">
      <span class="spinner-border spinner-border-xl"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { shallowDiff } from '@/helpers';
import formValidationMixin from '@/mixins/formValidationMixin';
import momentMixin from '@/mixins/momentMixin';
import paginationMixin from '@/mixins/paginationMixin';
import { PageParser } from '@/helpers';
import CofradeService from '@/services/cofrade.service';

export default {
  name: 'ModifyPages',
  mixins: [formValidationMixin, momentMixin, paginationMixin],
  data() {
    return {
      cofrades: null,
      areCofradesInitialized: false,
      search: '',
      pageOfItems: [],
    }
  },
  computed: {
    ...mapGetters({
      getAllCofrades: 'cofrade/getAllCofrades',
      getCofradeById: 'cofrade/getCofradeById',
      areCofradesLoaded: 'cofrade/areLoaded',
      getAllBooks: 'book/getAllBooks',
      areBooksLoaded: 'book/areLoaded',
    }),
    filteredCofrades() {
      function generalize(str) {
        return str
          .replace(/á/g, "a")
          .replace(/é/g, "e")
          .replace(/í/g, "i")
          .replace(/ó/g, "o")
          .replace(/ú/g, "u")
          .replace(/ü/g, "u")
          .replace(/Á/g, "a")
          .replace(/É/g, "e")
          .replace(/Í/g, "i")
          .replace(/Ó/g, "o")
          .replace(/Ú/g, "u")
          .replace(/Ü/g, "u");
      }
      return this.cofrades.filter(cofrade => {
        return (
          generalize((cofrade.full_name || '').toLowerCase()).includes(generalize(this.search.toLowerCase())) ||
          generalize((cofrade.full_name_switched || '').toLowerCase()).includes(generalize(this.search.toLowerCase()))
        );
      });
    },
  },
  watch: {
    getAllCofrades: {
      async handler(newCofradesArr, oldCofradesArr) {
        if (this.cofrades === null) {
          this.areCofradesInitialized = false;
          this.cofrades = JSON.parse(JSON.stringify(newCofradesArr));
          for (var cofrade of this.cofrades) {
            this.$set(cofrade, 'parsed_page_number', this.decodePageNumber(cofrade.page_number));
            this.$set(cofrade, 'page_turned', this.decodePageTurned(cofrade.page_number));
          }
          this.areCofradesInitialized = true;
        }
        else {
          var acc = 0;
          for (let index = 0; index < newCofradesArr.length; index++) {
            if (newCofradesArr[index].cofrade_id === (oldCofradesArr && oldCofradesArr[index-acc] && oldCofradesArr[index-acc].cofrade_id)) {
              Object.assign(this.cofrades[index], shallowDiff(newCofradesArr[index], oldCofradesArr[index-acc]));
            } else {
              acc++;
              this.cofrades.splice(index, 0, JSON.parse(JSON.stringify(newCofradesArr[index])));
            }
            this.$set(this.cofrades[index], 'parsed_page_number', this.decodePageNumber(this.cofrades[index].page_number));
            this.$set(this.cofrades[index], 'page_turned', this.decodePageTurned(this.cofrade[index].page_turned));
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAllCofrades: 'cofrade/fetchAllCofrades',
      fetchAllBooks: 'book/fetchAllBooks',
      fetchAllPages: 'page/fetchAllPages',
    }),
    encodePageNumber: PageParser.encodePageNumber,
    decodePageNumber: PageParser.decodePageNumber,
    decodePageTurned: PageParser.decodePageTurned,
    async onSubmit(cofrade) {
      this.$set(cofrade, 'loading', true);
      await CofradeService.update({
        cofrade_id: cofrade.cofrade_id,
        book_id: cofrade.book_id,
        page_number: this.encodePageNumber(cofrade.parsed_page_number, cofrade.page_turned)
      })
        .catch(error => this.$toast.error(error.message));
      this.$set(cofrade, 'loading', false);
    },
    isModified(cofrade) {
      return Object.keys(shallowDiff(cofrade, {
        ...this.getCofradeById(cofrade.cofrade_id),
        parsed_page_number: this.decodePageNumber(cofrade.page_number),
        page_turned: this.decodePageTurned(cofrade.page_number)
      })).length !== 0;
    }
  },
  async created() {
    if (!this.areCofradesLoaded) this.fetchAllCofrades();
    else {
      this.cofrades = JSON.parse(JSON.stringify(this.getAllCofrades));
      for (var cofrade of this.cofrades) {
        this.$set(cofrade, 'parsed_page_number', this.decodePageNumber(cofrade.page_number));
        this.$set(cofrade, 'page_turned', this.decodePageTurned(cofrade.page_number));
      }
      this.areCofradesInitialized = true;
    }
    if (!this.areBooksLoaded) this.fetchAllBooks();
  },
}
</script>