import CofradeService from "@/services/cofrade.service";

export default {
  fetchAllCofrades({ commit }) {
    return new Promise((resolve, reject) => {
      CofradeService.getAll()
        .then((cofrades) => {
          commit("SET_COFRADES_MAP", cofrades.data)
          resolve('success');
        })
        .catch((error) => {
          reject(error.response?.data?.status);
        })
    });
  },
  setCurrentCofradeId({ commit }, cofrade_id) {
    commit("SET_CURRENT_COFRADE_ID", cofrade_id);
  },
  fetchCurrentCofradeConjoints({ commit, state }) {
    return new Promise((resolve, reject) => {
      CofradeService.getConjoints(state.current_cofrade_id)
        .then((conjoints) => {
          commit("SET_CURRENT_COFRADE_CONJOINTS_MAP", conjoints.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error.response?.data?.status);
        })
    });
  },
  appendConjoint({ commit }, conjoint) {
    commit("ADD_CONJOINT", conjoint);
    commit("REMOVE_PREDICTED_CONJOINT", conjoint.cofrade_id);
  },
  removeConjoint({ commit }, conjoint) {
    commit("REMOVE_CONJOINT", conjoint.cofrade_id);
    commit("ADD_PREDICTED_CONJOINT", conjoint);
  },
  fetchCurrentCofradePredictedConjoints({ commit, state }) {
    return new Promise((resolve, reject) => {
      CofradeService.getPredictedConjoints(state.current_cofrade_id)
        .then((conjoints) => {
          commit("SET_CURRENT_COFRADE_PREDICTED_CONJOINTS_MAP", conjoints.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error.response?.data?.status);
        })
    });
  },
  cleanCurrentCofradeId({ commit }) {
    commit("SET_CURRENT_COFRADE_ID", null);
    commit("SET_CURRENT_COFRADE_CONJOINTS_MAP", null);
  },
  cleanCurrentCofradeConjointsMap({ commit }) {
    commit("SET_CURRENT_COFRADE_CONJOINTS_MAP", null);
  },
  cleanCurrentCofradePredictedConjointsMap({ commit }) {
    commit("SET_CURRENT_COFRADE_PREDICTED_CONJOINTS_MAP", null);
  }
};