<template>
  <div class="container-fluid" id="CofradeCreate">
    <div class="card my-3">
      <div class="card-header">Nuevo cofrade</div>
      <div class="card-body">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="form-row">
              <ValidationProvider class="form-group col-sm-4 mb-1" name="nombre" tag="div" rules="alpha_spaces|required|max:100" v-slot="{ classes }">
                <label for="inputName">Nombre</label>
                <input v-focus v-model="cofrade.first_name" type="text" class="form-control form-control-sm" :class="classes" id="inputName" placeholder="Nombre">
              </ValidationProvider>
              <ValidationProvider class="form-group col-sm-4 mb-1" name="primer apellido" tag="div" rules="alpha_spaces|required|max:100" v-slot="{ classes }">
                <label for="inputLastName1">Primer apellido</label>
                <input v-model="cofrade.last_name_1" type="text" class="form-control form-control-sm" :class="classes" id="inputLastName1" placeholder="Primer apellido">
              </ValidationProvider>
              <ValidationProvider class="form-group col-sm-4 mb-1" name="segundo apellido" tag="div" rules="alpha_spaces|max:100" v-slot="{ classes }">
                <label for="inputLastName2">Segundo apellido</label>
                <input v-model="cofrade.last_name_2" type="text" class="form-control form-control-sm" :class="classes" id="inputLastName2" placeholder="Segundo apellido">
              </ValidationProvider>
            </div>
            <div class="form-row mb-2">
              <div class="col-md-4">
                <div class="card">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-check ml-1">
                        <input v-model="cofrade.presbyter" class="form-check-input" type="checkbox" id="inputPresbyter">
                        <label class="form-check-label" for="inputPresbyter">Presbítero</label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-check ml-1">
                        <input v-model="cofrade.authority" class="form-check-input" type="checkbox" id="inputAuthority">
                        <label class="form-check-label" for="inputAuthority">Autoridad</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label for="inputPhone1">Teléfonos</label>
                <ValidationProvider name="teléfono 1" rules="numeric|max:15" v-slot="{ classes }">
                  <input v-model="cofrade.phone_1" type="text" class="form-control form-control-sm" :class="classes" id="inputPhone1" placeholder="Teléfono 1">
                </ValidationProvider>
                <ValidationProvider name="teléfono 2" rules="numeric|max:15" v-slot="{ classes }">
                  <input v-model="cofrade.phone_2" type="text" class="form-control form-control-sm" :class="classes" id="inputPhone2" placeholder="Teléfono 2">
                </ValidationProvider>
              </div>
              <div class="form-group col-sm-8">
                <label for="inputPhone1">Dirección</label>
                <ValidationProvider name="dirección 1" rules="max:255" v-slot="{ classes }">
                  <input v-model="cofrade.address_1" type="text" class="form-control form-control-sm" :class="classes" id="inputAddress1" placeholder="Línea de dirección 1">
                </ValidationProvider>
                <ValidationProvider name="dirección 2" rules="max:255" v-slot="{ classes }">
                  <input v-model="cofrade.address_2" type="text" class="form-control form-control-sm" :class="classes" id="inputAddress2" placeholder="Línea de dirección 2">
                </ValidationProvider>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col">
                <label for="inputNotes">Notas</label>
                <textarea v-model="cofrade.notes" class="form-control form-control-sm" id="inputNotes" placeholder="Notas" rows="3"></textarea>
              </div>
            </div>
            <div v-if="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
            <div class="float-right">
              <button class="btn btn-danger text-uppercase" :disabled="loading" type="submit">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span> Crear</span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CofradeService from '@/services/cofrade.service';
import formValidationMixin from '@/mixins/formValidationMixin';

export default {
  name: 'CofradeCreate',
  mixins: [formValidationMixin],
  props: ['redirect', 'pop'],
  data () {
    return {
      cofrade: {},
      errorMessage: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      getCofradeById: 'cofrade/getCofradeById',
    })
  },
  methods: {
    ...mapActions({
      appendConjoint: 'cofrade/appendConjoint',
    }),
    async onSubmit() {
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      this.loading = true;
      await CofradeService.post(this.cofrade)
        .then(async (response) => {
          this.cofrade = {};
          if (this.redirect) {
            await sleep(1000);
            this.$router.push({ name: 'cofrade-profile', params: { cofrade_id: response.data.cofrade_id } });
          }
          else if (this.pop) {
            await sleep(1000);
            this.appendConjoint(this.getCofradeById(response.data.cofrade_id));
          }
        })
        .catch((error) => this.errorMessage = error.message);
      this.loading = false;
    }
  },
}
</script>