import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import configureAxios from '@/api/AxiosConfig'
configureAxios();

import "@/plugins/bootstrap";
import "@/plugins/jw-vue-pagination";
import "@/plugins/font-awesome";
import "@/plugins/vue-toast-notification";

import "./assets/css/styles.css";

import configureEventSource from "@/api/eventsource";
configureEventSource();

Vue.config.productionTip = false

Vue.directive('focus', {
  inserted: function(el) {
    el.focus();
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
