import cofradeCreation from './cofrade/cofradeCreation';
import cofradeUpdate from './cofrade/cofradeUpdate';
import cofradeDeletion from './cofrade/cofradeDeletion';
import paymentCreation from './payment/paymentCreation';
import paymentUpdate from './payment/paymentUpdate';
import paymentDeletion from './payment/paymentDeletion';
import shareCreation from './share/shareCreation';
import shareUpdate from './share/shareUpdate';
import shareDeletion from './share/shareDeletion';
import bookCreation from './book/bookCreation';
import bookUpdate from './book/bookUpdate';
import bookDeletion from './book/bookDeletion';

const Handlers = [
  cofradeCreation, cofradeUpdate, cofradeDeletion,
  paymentCreation, paymentUpdate, paymentDeletion,
  shareCreation, shareUpdate, shareDeletion,
  bookCreation, bookUpdate, bookDeletion
];

export default Handlers;