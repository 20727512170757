function compare(a, b) {
  if (a.payment_id > b.payment_id) return -1;
  if (a.payment_id < b.payment_id) return 1;
  return 0;
}

export default {
  areYearPaymentsLoaded: (state) => {
    return state.year_payments_map ? true : false;
  },
  areCofradePaymentsLoaded: (state) => {
    return state.cofrade_payments_map ? true : false;
  },
  getYearPayments: (state) => {
    return state.year_payments_map
      ? Object.values(state.year_payments_map).sort(compare)
      : [];
  },
  getCofradePayments: (state) => {
    return state.cofrade_payments_map
      ? Object.values(state.cofrade_payments_map).sort(compare)
      : [];
  },
};