<template>
  <div id="List">
    <form class="sticky-top form-inline justify-content-center bg-accent pt-2 pb-2">
      <font-awesome-icon icon="search" aria-hidden="true"/>
      <input v-focus @keydown.enter.prevent class="form-control form-control-sm ml-3 w-50" type="text" v-model="search" placeholder="Buscar" aria-label="Buscar">
      <button v-if="!createNewCofrade" class="btn btn-sm btn-primary ml-3 w-25" v-on:click.stop.prevent="createNewCofrade = true">
        <font-awesome-icon icon="plus" aria-hidden="true"/> Nuevo
      </button>
      <button v-else class="btn btn-sm btn-warning ml-3 w-25" v-on:click.stop.prevent="createNewCofrade = false">
        <font-awesome-icon icon="ban" aria-hidden="true"/><span class="hidden-fourth"> Cancelar</span>
      </button>
    </form>
    <CofradeCreate v-if="createNewCofrade" :redirect="true"/>
    <div class="container-fluid mt-2">
      <div v-if="areCofradesLoaded">
        <table class="sticky-top-2 table table-sm table-hover" id="Cofrades">
          <thead class="bg-dark text-light">
            <tr>
              <th scope="col"></th>
              <th scope="col">Nombre</th>
              <th scope="col" class="hidden-fourth">Año entrada</th>
              <th scope="col">Pagado<span class="hidden-second"> hasta</span></th>
              <th scope="col" class="hidden-second">Teléfono<span class="hidden-first"> 1</span></th>
              <th scope="col" class="hidden-first">Teléfono 2</th>
              <th scope="col" class="hidden-second">Direccion</th>
              <th scope="col" class="hidden-third">Notas</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cofrade in pageOfItems" :key="cofrade.cofrade_id">
              <td class="fit" role="group">
                <router-link type="button" class="btn btn-sm btn-primary" :to="{name: 'cofrade-profile', params: { cofrade_id: cofrade.cofrade_id }}">
                  <font-awesome-icon icon="indent" aria-hidden="true"/>
                </router-link>
              </td>
              <td>
                <router-link tag="span" style="cursor: pointer" :to="{name: 'cofrade-profile', params: { cofrade_id: cofrade.cofrade_id }}">
                  <b>{{cofrade.full_name_switched}}</b>
                  <span v-if="cofrade.nickname"> ({{cofrade.nickname}})</span>
                  <span v-if="cofrade.deceased"> ✟</span>
                </router-link>
              </td>
              <td class="hidden-fourth full-content">{{year(cofrade.entry_date) || 'No registrado'}}</td>
              <td class="full-content"><b>{{payedTill(cofrade)}}</b></td>
              <td class="hidden-second">{{cofrade.phone_1 || "-"}}</td>
              <td class="hidden-first">{{cofrade.phone_2 || "-"}}</td>
              <td class="hidden-second">{{cofrade.address_1 || "-"}}<br v-if="cofrade.address_2">{{cofrade.address_2 || ""}}</td>
              <td class="hidden-third">{{cofrade.notes || "-"}}</td>
            </tr>
          </tbody>
        </table>
        <div class="container-fluid form-inline justify-content-center">
          <jw-pagination class="pagination" :items="filteredCofrades" :pageSize="30" :maxPages="5" :labels="customLabels" :disableDefaultStyles="true" @changePage="onChangePage"></jw-pagination>
        </div>
        <button class="btn btn-sm btn-primary" style="float: right;" v-on:click.stop.prevent="downloadPdf()">Descargar listado completo</button>
        <div>&nbsp;</div>
      </div>
      <div v-else class="mt-2 text-center">
        <span class="spinner-border spinner-border-xl"></span>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import paginationMixin from '@/mixins/paginationMixin';
import { PaymentCalculator, TimeFormatter } from '@/helpers';
import CofradeCreate from '@/components/cofrade/CofradeCreate';
import CofradeService from "@/services/cofrade.service";


export default {
  name: 'List',
  mixins: [paginationMixin],
  components: {
    CofradeCreate,
  },
  data() {
    return {
      search: '',
      pageOfItems: [],
      createNewCofrade: false,
    }
  },
  computed: {
    ...mapGetters({
      cofrades: 'cofrade/getAllCofrades',
      areCofradesLoaded: 'cofrade/areLoaded',
    }),
    filteredCofrades() {
      function generalize(str) {
        return str
          .replace(/á/g, "a")
          .replace(/é/g, "e")
          .replace(/í/g, "i")
          .replace(/ó/g, "o")
          .replace(/ú/g, "u")
          .replace(/ü/g, "u")
          .replace(/Á/g, "a")
          .replace(/É/g, "e")
          .replace(/Í/g, "i")
          .replace(/Ó/g, "o")
          .replace(/Ú/g, "u")
          .replace(/Ü/g, "u")
          .replace(/,/g, "");
      }
      return this.cofrades.filter(cofrade => {
        return (
          generalize((cofrade.nickname || '').toLowerCase()).includes(generalize(this.search.toLowerCase())) ||
          generalize((cofrade.full_name || '').toLowerCase()).includes(generalize(this.search.toLowerCase())) ||
          generalize((cofrade.full_name_switched || '').toLowerCase()).includes(generalize(this.search.toLowerCase()))
        );
      });
    },
  },
  methods: {
    ...mapActions({
      fetchAllCofrades: 'cofrade/fetchAllCofrades',
    }),
    payedTill: PaymentCalculator.payedTill,
    year: TimeFormatter.year,
    downloadPdf() {
      CofradeService.getListPdf().then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `cofradesList_${(new Date).toISOString()}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
    }
  },
  created() {
    if (!this.areCofradesLoaded) this.fetchAllCofrades();
  },
}
</script>