<template>
  <footer id="Footer" class="footer bg-dark font-small text-center">
    <div class="container">
      <span class="text-light font-weight-bold">Copyright © {{currentYear()}} Santiago Pérez Acuña</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    currentYear: () => (new Date()).getFullYear(),
  },
}
</script>

<style scoped>
.footer {
  background-color: var(--primary) !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 75%;
}

@media (max-width: 350px) {
  .md-append {
    display: none;
  }
}
</style>