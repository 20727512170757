<template>
  <div v-if="areCofradesLoaded && arePaymentsLoaded && areSharesLoaded" class="container-fluid" id="Dashboard">
    <div class="row">
      <div class="col-md-6 pt-2 pb-2">
        <div class="card">
          <div class="card-header font-weight-bold">Cofrades pagados</div>
          <div class="card-body">
            <span style="font-size: 250%; font-weight: 700">{{payedTillThisYear}}</span>
            <span style="font-size: 75%; font-weight: 500"> pagados de </span>
            <span style="font-size: 250%; font-weight: 700">{{payedTillLastYear}}</span>
            <span style="font-size: 75%; font-weight: 500"> esperados</span>
          </div>
        </div>
      </div>
      <div class="col-md-6 pt-2 pb-2">
        <div class="card">
          <div class="card-header font-weight-bold">Nuevos cofrades</div>
          <div class="card-body">
            <span style="font-size: 250%; font-weight: 700">{{entryThisYear}}</span>
            <span style="font-size: 75%; font-weight: 500"> nuevos de </span>
            <span style="font-size: 250%; font-weight: 700">{{entryLastYear}}</span>
            <span style="font-size: 75%; font-weight: 500"> esperados</span>
          </div>
        </div>
      </div>
      <div class="col-md-6 pt-2 pb-2">
        <div class="card">
          <div class="card-header font-weight-bold">Años pagados este año</div>
          <div class="card-body">
            <span style="font-size: 250%; font-weight: 700">{{yearsPayedThisYear}}</span>
            <span style="font-size: 75%; font-weight: 500"> años</span>
          </div>
        </div>
      </div>
      <div class="col-md-6 pt-2 pb-2">
        <div class="card">
          <div class="card-header font-weight-bold">Importe recaudado este año</div>
          <div class="card-body">
            <span style="font-size: 250%; font-weight: 700">{{profitThisYear}}</span>
            <span style="font-size: 75%; font-weight: 500"> €</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="mt-2 text-center">
    <span class="spinner-border spinner-border-xl"></span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment';

export default {
  name: 'Dashboard',
  data() {
    return {
      year: parseInt(moment().format('YYYY')),
    }
  },
  computed: {
    ...mapGetters({
      cofrades: 'cofrade/getAllCofrades',
      areCofradesLoaded: 'cofrade/areLoaded',
      payments: 'payment/getYearPayments',
      arePaymentsLoaded: 'payment/areYearPaymentsLoaded',
      getEntryShares: 'share/getEntryShares',
      getDefaultOrdinaryShare: 'share/getDefaultOrdinaryShare',
      getShareById: 'share/getShareById',
      areSharesLoaded: 'share/areLoaded',
    }),
    payedTillThisYear: function() {
      return this.cofrades.reduce(
        (currentTotal, cofrade) => 
          currentTotal + (((new Date(cofrade.entry_date)).getFullYear() + cofrade.payed_years >= this.year))
        , 0);
    },
    payedTillLastYear: function() {
      return this.cofrades.reduce(
        (currentTotal, cofrade) => 
          currentTotal + (
            ((new Date(cofrade.entry_date)).getFullYear() + cofrade.payed_years) >= (this.year - 1)
            &&
            ((new Date(cofrade.entry_date)).getFullYear() < this.year)
          )
        , 0);
    },
    entryThisYear: function() {
      return this.cofrades.reduce(
        (currentTotal, cofrade) => 
          currentTotal + (((new Date(cofrade.entry_date)).getFullYear() >= this.year))
        , 0);
    },
    entryLastYear: function() {
      return this.cofrades.reduce(
        (currentTotal, cofrade) => 
          currentTotal + (((new Date(cofrade.entry_date)).getFullYear() == this.year - 1))
        , 0);
    },
    yearsPayedThisYear: function() {
      return this.payments.reduce(
        (currentTotal, payment) => 
          currentTotal + ((payment.share_id === this.getDefaultOrdinaryShare.share_id)*payment.years)
        , 0);
    },
    profitThisYear: function() {
      return this.payments.reduce(
        (currentTotal, payment) => {
          let paymentValue = (this.getShareById(payment.share_id).price * (payment.years || 1))
          return currentTotal + paymentValue;
        }
      , 0);
    }
  },
  methods: {
    ...mapActions({
      fetchAllCofrades: 'cofrade/fetchAllCofrades',
      fetchYearPayments: 'payment/fetchYearPayments',
      fetchAllShares: 'share/fetchAllShares',
      cleanPayments: 'payment/cleanPayments',
    }),
  },
  created() {
    this.fetchYearPayments();
    if (!this.areCofradesLoaded) this.fetchAllCofrades();
    if (!this.areSharesLoaded) this.fetchAllShares();
  },
  destroyed() {
    this.cleanPayments();
  }
}
</script>