import AuthService from "@/services/auth.service";
import ApiRoutes from "@/api/ApiRoutes";
import axios from 'axios'

export default class CofradeService {
  static async getAll() {
    return (await axios.get(ApiRoutes.Cofrades.GetAll, {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getListPdf() { // TODO CHECK IF THIS WORKS THIS WAY
    return (await axios.get(ApiRoutes.Cofrades.GetListPdf, {
      headers: AuthService.getAuthHeader(),
      responseType: 'blob',
    }));
  }
  static async getById(cofrade_id) {
    return (await axios.get(ApiRoutes.Cofrades.GetById(cofrade_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getConjoints(cofrade_id) {
    return (await axios.get(ApiRoutes.Cofrades.GetConjointsById(cofrade_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getPredictedConjoints(cofrade_id) {
    return (await axios.get(ApiRoutes.Cofrades.GetPredictedConjointsById(cofrade_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getPaymentsById(cofrade_id) {
    return (await axios.get(ApiRoutes.Cofrades.GetPaymentsById(cofrade_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async update(cofrade) {
    const parsedCofrade = this.parse(cofrade);
    return (await axios.put(ApiRoutes.Cofrades.GetById(parsedCofrade.cofrade_id), parsedCofrade,
      { headers: AuthService.getAuthHeader() }
    )).data;
  }
  static async post(cofrade) {
    const parsedCofrade = this.parse(cofrade);
    return (await axios.post(ApiRoutes.Cofrades.Root, parsedCofrade,
      { headers: AuthService.getAuthHeader() }
    )).data;
  }
  static async delete(cofrade_id) {
    return (await axios.post(ApiRoutes.Cofrades.GetById, cofrade_id,
      { headers: AuthService.getAuthHeader() }
    )).data;
  }
  static parse(cofrade) {
    let parsedCofrade = {};
    Object.assign(parsedCofrade,
      cofrade.cofrade_id   !== null && cofrade.cofrade_id   !== undefined ? {cofrade_id   : parseInt(cofrade.cofrade_id)                  } : null,
      cofrade.first_name   !== null && cofrade.first_name   !== undefined ? {first_name   : cofrade.first_name                            } : null,
      cofrade.last_name_1  !== null && cofrade.last_name_1  !== undefined ? {last_name_1  : cofrade.last_name_1                           } : null,
      cofrade.last_name_2  !== null && cofrade.last_name_2  !== undefined ? {last_name_2  : cofrade.last_name_2                           } : null,
      cofrade.nickname     !== null && cofrade.nickname     !== undefined ? {nickname     : cofrade.nickname                              } : null,
      cofrade.gender       !== null && cofrade.gender       !== undefined ? {gender       : cofrade.gender === "male" ? "male" : "female" } : null,
      cofrade.entry_date   !== null && cofrade.entry_date   !== undefined ? {entry_date   : new Date(cofrade.entry_date)                  } : null,
      cofrade.book_id      !== null && cofrade.book_id      !== undefined ? {book_id      : parseInt(cofrade.book_id)                     } : null,
      cofrade.page_number  !== null && cofrade.page_number  !== undefined ? {page_number  : parseInt(cofrade.page_number)                 } : null,
      cofrade.phone_1      !== null && cofrade.phone_1      !== undefined ? {phone_1      : cofrade.phone_1                               } : null,
      cofrade.phone_2      !== null && cofrade.phone_2      !== undefined ? {phone_2      : cofrade.phone_2                               } : null,
      cofrade.address_1    !== null && cofrade.address_1    !== undefined ? {address_1    : cofrade.address_1                             } : null,
      cofrade.address_2    !== null && cofrade.address_2    !== undefined ? {address_2    : cofrade.address_2                             } : null,
      cofrade.presbyter    !== null && cofrade.presbyter    !== undefined ? {presbyter    : (cofrade.presbyter === "true"    || cofrade.presbyter === true    || cofrade.presbyter === 1    || cofrade.presbyter === "1"   ) ? true : false } : null,
      cofrade.authority    !== null && cofrade.authority    !== undefined ? {authority    : (cofrade.authority === "true"    || cofrade.authority === true    || cofrade.authority === 1    || cofrade.authority === "1"   ) ? true : false } : null,
      cofrade.deceased     !== null && cofrade.deceased     !== undefined ? {deceased     : (cofrade.deceased === "true"     || cofrade.deceased === true     || cofrade.deceased === 1     || cofrade.deceased === "1"    ) ? true : false } : null,
      cofrade.deregistered !== null && cofrade.deregistered !== undefined ? {deregistered : (cofrade.deregistered === "true" || cofrade.deregistered === true || cofrade.deregistered === 1 || cofrade.deregistered === "1") ? true : false } : null,
      cofrade.notes        !== null && cofrade.notes        !== undefined ? {notes        : cofrade.notes                                 } : null
    );
    return parsedCofrade;
  }
}