import store from '@/store';
import CofradeService from "@/services/cofrade.service";
import PaymentService from '@/services/payment.service';

const paymentCreation = {
  eventType: 'PAYMENT_CREATION',
  handle: function (event) {
    const cofrade_id = JSON.parse(event.data).cofrade_id
    const payer_id = JSON.parse(event.data).payer_id
    const payment_id = JSON.parse(event.data).payment_id
    if (
      store.state.cofrade.current_cofrade_id === payer_id &&
      payer_id !== cofrade_id
    ) {
      CofradeService.getById(cofrade_id)
        .then((cofrade) => {
          store.commit("cofrade/ADD_CONJOINT_IF_NOT_FOUND", cofrade.data);
        })
        .catch(() => {});
    }
    PaymentService.getById(payment_id)
      .then((payment) => store.commit("payment/ADD_PAYMENT", payment.data))
      .catch(() => {});
  }
}

export default paymentCreation;