import Vue from 'vue'

export default {
  SET_COFRADES_MAP(state, cofradesMap) {
    state.cofrades_map = cofradesMap;
    if (cofradesMap !== null)
      Vue.$toast.info("Cofrades cargados!");
  },
  SET_CURRENT_COFRADE_ID(state, cofrade_id) {
    state.current_cofrade_id = cofrade_id;
  },
  SET_CURRENT_COFRADE_CONJOINTS_MAP(state, conjointsMap) {
    state.current_cofrade_conjoints_map = conjointsMap;
    if (conjointsMap !== null)
      Vue.$toast.info("Cofrades asociados cargados!");
  },
  SET_CURRENT_COFRADE_PREDICTED_CONJOINTS_MAP(state, predictedConjointsMap) {
    state.current_cofrade_predicted_conjoints_map = predictedConjointsMap;
    if (predictedConjointsMap !== null)
      Vue.$toast.info("Cofrades predichos cargados!");
  },
  SET_COFRADE(state, cofrade) {
    if (state.cofrades_map) {
      Vue.set(state.cofrades_map, cofrade.cofrade_id, cofrade);
      Vue.$toast.info("Cofrade actualizado!");
    }
    if (state.current_cofrade_conjoints_map && state.current_cofrade_conjoints_map[cofrade.cofrade_id])
      Vue.set(state.current_cofrade_conjoints_map, cofrade.cofrade_id, cofrade);
    if (state.current_cofrade_predicted_conjoints_map && state.current_cofrade_predicted_conjoints_map[cofrade.cofrade_id])
      Vue.set(state.current_cofrade_predicted_conjoints_map, cofrade.cofrade_id, cofrade);
  },
  ADD_COFRADE(state, cofrade) {
    if (state.cofrades_map) {
      Vue.set(state.cofrades_map, cofrade.cofrade_id, cofrade);
      Vue.$toast.info("Nuevo cofrade cargado!");
    }
    if (state.current_cofrade_conjoints_map && state.current_cofrade_conjoints_map[cofrade.cofrade_id])
      Vue.set(state.current_cofrade_conjoints_map, cofrade.cofrade_id, cofrade);
  },
  ADD_CONJOINT(state, cofrade) {
    if (state.current_cofrade_conjoints_map !== null) {
      if (Object.keys(state.current_cofrade_conjoints_map).map(Number).includes(cofrade.cofrade_id))
        Vue.$toast.warning("El cofrade ya está asociado!")
      else {
        Vue.set(state.current_cofrade_conjoints_map, cofrade.cofrade_id, cofrade);
        Vue.$toast.info("Cofrade asociado cargado!");
      }
    }
  },
  ADD_CONJOINT_IF_NOT_FOUND(state, cofrade) {
    if (state.current_cofrade_conjoints_map !== null) {
      if (!Object.keys(state.current_cofrade_conjoints_map).map(Number).includes(cofrade.cofrade_id)) {
        Vue.set(state.current_cofrade_conjoints_map, cofrade.cofrade_id, cofrade);
        Vue.$toast.info("Cofrade asociado cargado!");
      }
    }
  },
  ADD_PREDICTED_CONJOINT(state, cofrade) {
    if (state.current_cofrade_predicted_conjoints_map) {
      if (!Object.keys(state.current_cofrade_predicted_conjoints_map).map(Number).includes(cofrade.cofrade_id)) {
        Vue.set(state.current_cofrade_predicted_conjoints_map, cofrade.cofrade_id, cofrade);
      }
    }
  },
  REMOVE_COFRADE(state, cofrade_id) {
    if (state.cofrades_map && state.cofrades_map[cofrade_id]) {
      Vue.delete(state.cofrades_map, cofrade_id);
      Vue.$toast.info("Cofrade eliminado!");
    }
    if (state.current_cofrade_conjoints_map && state.current_cofrade_conjoints_map[cofrade_id])
      Vue.delete(state.current_cofrade_conjoints_map, cofrade_id);
    if (state.current_cofrade_predicted_conjoints_map && state.current_cofrade_predicted_conjoints_map[cofrade_id]) {
      Vue.delete(state.current_cofrade_predicted_conjoints_map, cofrade_id);
    }
  },
  REMOVE_CONJOINT(state, cofrade_id) {
    if (state.current_cofrade_conjoints_map && state.current_cofrade_conjoints_map[cofrade_id]) {
      Vue.delete(state.current_cofrade_conjoints_map, cofrade_id);
    }
  },
  REMOVE_PREDICTED_CONJOINT(state, cofrade_id) {
    if (state.current_cofrade_predicted_conjoints_map && state.current_cofrade_predicted_conjoints_map[cofrade_id]) {
      Vue.delete(state.current_cofrade_predicted_conjoints_map, cofrade_id);
    }
  }
};
