<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              DEFAULT HEADER
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              DEFAULT BODY
            </slot>
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger text-uppercase modal-btn" v-on:click.stop.prevent="$emit('discard')">
              <slot name="btn-discard">
                DISCARD
              </slot>
            </button>
            <button class="btn btn-success text-uppercase modal-btn" v-on:click.stop.prevent="$emit('close')">
              <slot name="btn-close">
                CLOSE
              </slot>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
}
</script>