import store from '@/store';

const paymentDeletion = {
  eventType: 'PAYMENT_DELETION',
  handle: function (event) {
    const payment_id = JSON.parse(event.data).payment_id;
    store.commit('payment/REMOVE_PAYMENT', payment_id);
  }
}

export default paymentDeletion;