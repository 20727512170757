import store from "@/store";
import BookService from '@/services/book.service';

const bookCreation = {
  eventType: "BOOK_CREATION",
  handle: function(event) {
    const book_id = JSON.parse(event.data).book_id;
    BookService.getById(book_id)
      .then((book) => store.commit("book/ADD_BOOK", book.data))
      .catch(() => {});
  },
};

export default bookCreation;
