<template>
  <div id="IndexAdmin">
    <Sidebar/>
    <div class="content-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/admin/Sidebar';

export default {
  name: 'IndexAdmin',
  components: {
    Sidebar
  }
}
</script>

<style scoped>
@media only screen and (min-width: 626px) {
  .content-container {
    padding-left: 220px;
  }
}
</style>