import BookService from "@/services/book.service";

export default {
  fetchAllBooks({ commit }) {
    return new Promise((resolve, reject) => {
      BookService.getAll()
        .then((books) => {
          commit("SET_BOOKS_MAP", books.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error.response?.data?.status);
        })
    });
  },
  fetchCurrentBookCofrades({ commit, state }) {
    return new Promise((resolve, reject) => {
      BookService.getCofradesById(state.current_book_id)
        .then((cofrades) => {
          commit("SET_CURRENT_BOOK_COFRADES_MAP", cofrades.data)
          resolve('success');
        })
        .catch((error) => {
          reject(error.response?.data?.status);
        })
    });
  },
  setCurrentBookId({ commit }, book_id) {
    commit("SET_CURRENT_BOOK_ID", book_id);
  },
  cleanCurrentBookId({ commit }) {
    commit("SET_CURRENT_BOOK_ID", null);
    commit("SET_CURRENT_BOOK_COFRADES_MAP", null);
  },
};
