import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'

export default function configureAxios() {
  axios.interceptors.response.use(undefined, function (error) {
    if (error?.response?.status == 401) {
      store.dispatch("auth/logout");
      router.push("/login");
      return Promise.reject(error.response?.data?.status);
    } else if (error?.response?.data?.status?.message) {
      Vue.$toast.error(`${error.response?.data?.status?.message}`, {
        position: "bottom-right",
      });
      return Promise.reject(error.response?.data?.status);
    } else {
      Vue.$toast.error(`${error?.message || 'Error'}`, {
        position: "bottom-right",
      });
      console.log(`Axios Error: ${JSON.stringify(error)}`);
      return Promise.reject(error);
    }
  })
}