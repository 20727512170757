<template>
  <div class="container-fluid mt-2" id="Recent">
    <div v-if="areCofradesLoaded && areSharesLoaded && arePaymentsLoaded">
      <table class="sticky-top-1 table table-sm table-hover">
        <thead class="bg-dark text-light">
          <tr>
            <th scope="col">Fecha</th>
            <th scope="col" class="hidden-third">Pagador</th>
            <th scope="col">Cofrade</th>
            <th scope="col" class="hidden-fourth">Cuota</th>
            <th scope="col">Años</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in yearPayments" :key="payment.payment_id">
            <td>{{moment({ format: 'calendar', date: payment.created_at })}}</td>
            <td class="hidden-third">
              <router-link tag="span" style="cursor: pointer" :to="{name: 'cofrade-profile', params: { cofrade_id: payment.payer_id }}">
                <span>{{getCofradeById(payment.payer_id).full_name}}</span>
                <span v-if="getCofradeById(payment.payer_id).nickname"> ({{getCofradeById(payment.payer_id).nickname}})</span>
                <span v-if="getCofradeById(payment.payer_id).deceased"> ✟</span>
              </router-link>
            </td>
            <td>
              <router-link tag="span" style="cursor: pointer" :to="{name: 'cofrade-profile', params: { cofrade_id: payment.cofrade_id }}">
                <b>{{getCofradeById(payment.cofrade_id).full_name}}</b>
                <span v-if="getCofradeById(payment.cofrade_id).nickname"> ({{getCofradeById(payment.cofrade_id).nickname}})</span>
                <span v-if="getCofradeById(payment.cofrade_id).deceased"> ✟</span>
              </router-link>
            </td>
            <td class="fit hidden-fourth">{{getShareById(payment.share_id).name}}</td>
            <td>{{payment.years}}</td>
            <td v-if="isIssuer || isAdmin" class="fit" role="group">
              <button v-on:click.stop.prevent="deletePayment(payment)" class="btn btn-sm btn-block btn-danger" :disabled="payment.deleting">
                <span v-if="payment.deleting" class="spinner-border align-middle" style="width: 11px; height: 11px;"></span>
                <font-awesome-icon v-else icon="trash" aria-hidden="true"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="text-center">
      <span class="spinner-border spinner-border-xl"></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PaymentService from '@/services/payment.service';
import momentMixin from '@/mixins/momentMixin';

export default {
  name: 'Recent',
  mixins: [momentMixin],
  data() {
    return {
      yearPayments: null,
      deleting: false
    }
  },
  computed: {
    ...mapGetters({
      isIssuer: 'auth/isIssuer',
      isAdmin: 'auth/isAdmin',
      getYearPayments: 'payment/getYearPayments',
      getShareById: 'share/getShareById',
      getCofradeById: 'cofrade/getCofradeById',
      arePaymentsLoaded: 'payment/areYearPaymentsLoaded',
      areSharesLoaded: 'share/areLoaded',
      areCofradesLoaded: 'cofrade/areLoaded',
    })
  },
  watch: {
    getYearPayments: {
      deep: true,
      handler() {
        this.yearPayments = JSON.parse(JSON.stringify(this.getYearPayments));
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAllCofrades: 'cofrade/fetchAllCofrades',
      fetchAllShares: 'share/fetchAllShares',
      fetchYearPayments: 'payment/fetchYearPayments',
      cleanPayments: 'payment/cleanPayments',
    }),
    async deletePayment(payment) {
      this.$set(payment, "deleting", true);
      await PaymentService.delete(payment.payment_id);
      this.$set(payment, "deleting", false);
    }
  },
  created() {
    this.fetchYearPayments();
    if (!this.areCofradesLoaded) this.fetchAllCofrades();
    if (!this.areSharesLoaded) this.fetchAllShares();
  },
  mounted() {
    this.yearPayments = JSON.parse(JSON.stringify(this.getYearPayments));
  },
  destroyed() {
    this.cleanPayments();
  },
}
</script>