export default class PageParser {
  static parsePageNumber(page_number) {
    return `${Math.floor((page_number+1)/2)}${(page_number%2) === 0 ? ' vto' : ''}`;
  }
  static decodePageNumber(page_number) {
    return Math.floor((page_number+1)/2)
  }
  static decodePageTurned(page_number) {
    return page_number%2===0;
  }
  static encodePageNumber(page_number, turned) {
    return page_number*2-1+turned;
  }
}