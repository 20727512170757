import { ValidationObserver, ValidationProvider, localize, configure, extend } from 'vee-validate';
import es from "vee-validate/dist/locale/es.json";
localize("es", es);

import { alpha_dash, alpha_spaces, max, min_value, numeric, required } from "vee-validate/dist/rules";
extend("alpha_dash", alpha_dash);
extend("alpha_spaces", alpha_spaces);
extend("max", max);
extend("min_value", min_value)
extend("numeric", numeric);
extend("required", required);
extend("natural", (value) => {
  return value >= 0;
});
extend("positive", (value) => {
  return value > 0;
});

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  }
}