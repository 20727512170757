<template>
  <nav class="navbar navbar-expand-md navbar-dark" id="Navbar">
    <div class="brand">
      <span class="navbar-brand"><img src="@/assets/logo.png" alt=""> Cofradía</span>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
      <ul class="navbar-nav mr-auto ml-auto">
        <li class="nav-item col full-content pr-4 pl-4">
          <router-link to="/" class="nav-link">
            <font-awesome-icon icon="home"/> Inicio
          </router-link>
        </li>
        <li v-if="isUser || isIssuer || isAdmin" class="nav-item col full-content pr-4 pl-4">
          <router-link :to="{name: 'cofrades'}" class="nav-link">
            <font-awesome-icon icon="user-shield" /> Cofrades
          </router-link>
        </li>
        <li v-if="isIssuer || isAdmin" class="nav-item col full-content pr-4 pl-4">
          <router-link :to="{name: 'recent'}" class="nav-link">
            <font-awesome-icon icon="history" /> Reciente
          </router-link>
        </li>
        <li v-if="isAdmin" class="nav-item col full-content">
          <router-link :to="{name: 'admin-dashboard'}" class="nav-link pr-4 pl-4">
            <font-awesome-icon icon="cog" /> Admin
          </router-link>
        </li>
      </ul>
      <ul class="navbar-nav d-flex flex-row justify-content-end">
        <li v-if="!isLoggedIn" class="nav-item">
          <router-link :to="{name: 'login'}" class="btn btn-secondary">
            <font-awesome-icon icon="sign-in-alt" /> Entrar
          </router-link>
        </li>
        <li v-else class="nav-item">
          <a class="btn btn-secondary" href @click.prevent="logout">
            <font-awesome-icon icon="sign-out-alt" />
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isUser: 'auth/isUser',
      isIssuer: 'auth/isIssuer',
      isAdmin: 'auth/isAdmin',
    })
  },
  methods: {
    ...mapActions({
      dispatchLogout: 'auth/logout'
    }),
    logout() {
      this.dispatchLogout();
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.navbar {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--primary) !important;
}
.navbar-toggler{
  padding-right: 7px;
  padding-left: 7px;
}
.navbar-toggler-icon{
  height: 23px;
}

.brand {
  border-left: solid 6px var(--accent);
  padding-left: 15px;
  padding-right: 15px;
}
.brand .navbar-brand {
  padding: 0;
  font-size: 110%;
  font-weight: 500;
}
.brand .navbar-brand img {
  height: 33.2px;
}

.nav-link {
  padding: 5px;
}
</style>