const ApiBaseUrl = process.env.VUE_APP_API_ORIGIN;
const BasePrefix = '/api';

const Base = ApiBaseUrl + BasePrefix;
const AuthBase = Base + '/auth';
const CofradesBase = Base + '/cofrades';
const PaymentsBase = Base + '/payments';
const SharesBase = Base + '/shares';
const BooksBase = Base + '/books';
const UsersBase = Base + '/users';

const ApiRoutes = {
  ApiBaseUrl: ApiBaseUrl,
  Auth: {
    Root: AuthBase,
    SignIn: AuthBase + '/signin',
    SignUp: AuthBase + '/signup'
  },
  Cofrades: {
    Root: CofradesBase,
    GetAll: CofradesBase,
    GetListPdf: CofradesBase + '/list',
    GetById: cofrade_id => CofradesBase + '/' + cofrade_id,
    GetConjointsById: cofrade_id => CofradesBase + '/' + cofrade_id + '/conjoints',
    GetPredictedConjointsById: cofrade_id => CofradesBase + '/' + cofrade_id + '/conjoints/predicted',
    GetPaymentsById: cofrade_id => CofradesBase + '/' + cofrade_id + '/payments',
  },
  Payments: {
    Root: PaymentsBase,
    GetAll: PaymentsBase,
    GetById: payment_id => PaymentsBase + '/' + payment_id,
    GetByYear: year => PaymentsBase + '?year=' + year,
  },
  Shares: {
    Root: SharesBase,
    GetAll: SharesBase,
    GetById: share_id => SharesBase + '/' + share_id,
    GetOrdinary: SharesBase + '?entry=false',
    GetOrdinaryDefault: SharesBase + '?entry=false&default',
    GetEntry: SharesBase + '?entry',
    GetEntryDefault: SharesBase + '?entry&default',
  },
  Books: {
    Root: BooksBase,
    GetAll: BooksBase,
    GetById: book_id => BooksBase + '/' + book_id,
    GetCofradesById: book_id => BooksBase + '/' + book_id + '/cofrades',
    GetCurrent: BooksBase + '/current',
  },
  Users: {
    Root: UsersBase,
    GetAll: UsersBase,
    GetByUsername: username => UsersBase + '/' + username,
  },
  EventSource: Base + '/updates'
}

export default ApiRoutes;