import ApiRoutes from "@/api/ApiRoutes";
import axios from "axios";
import store from "@/store";

export default class AuthService {
  static async login(username, password) {
    return (await axios.post(ApiRoutes.Auth.SignIn, { username, password })).data;
  }
  static getAuthHeader() {
    if (store.state.auth.user && store.state.auth.user.accessToken)
      return { "authorization": store.state.auth.user.accessToken }
    else return {}
  }
}