import auth from './auth';
import book from './book';
import cofrade from './cofrade';
import payment from './payment';
import share from './share';

export default {
  auth,
  book,
  cofrade,
  payment,
  share,
}