export default class TimeFormatter {
  static localeDate(dateString) {
    return dateString
      ? (new Date(dateString)).toLocaleDateString()
      : null;
  }
  static year(dateString) {
    return dateString
      ? (new Date(dateString)).getFullYear()
      : null;
  }
  static now() {
    return new Date();
  }
  static isYearCurrentOrAfterNow(year) {
    return year >= (new Date()).getFullYear();
  }
}