<template>
  <div v-if="areSharesLoaded && areCofradesLoaded && cofrade" id="Cofrade">
    <div class="container-fluid">
      <div class="card bg-primary mt-2">
        <div class="card-header">
          <span class="h5">{{cofrade.full_name_switched + (cofrade.nickname ? ` (${cofrade.nickname})` : '')}}</span>
          <router-link v-if="$route.name == 'cofrade-profile'" type="button" class="btn btn-sm btn-secondary float-right" :to="{name: 'cofrade-payments', params: { cofrade_id: cofrade.cofrade_id }}">
            Pagos <font-awesome-icon icon="money-bill-alt" aria-hidden="true"/>
          </router-link>
          <router-link v-else-if="$route.name == 'cofrade-payments'" type="button" class="btn btn-sm btn-secondary float-right" :to="{name: 'cofrade-profile', params: { cofrade_id: cofrade.cofrade_id }}">
            Ficha <font-awesome-icon icon="user-shield" aria-hidden="true"/>
          </router-link>
        </div>
      </div>
    </div>
    <router-view :key="cofrade.cofrade_id"/>
  </div>
  <div v-else-if="cofrade === undefined" class="container-fluid">
    <div class="mt-2 alert alert-danger">
      <span>Error 404 - El cofrade no existe</span>
    </div>
  </div>
  <div v-else class="mt-2 text-center">
    <span class="spinner-border spinner-border-xl"></span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "Cofrade",
  computed: {
    ...mapGetters({
      areCofradesLoaded: 'cofrade/areLoaded',
      areSharesLoaded: 'share/areLoaded',
      cofrade: 'cofrade/getCurrentCofrade',
    })
  },
  watch: {
    '$route': function() {
      this.cleanCurrentCofradeId();
      this.setCurrentCofradeId(this.$route.params.cofrade_id);
    }
  },
  methods: {
    ...mapActions({
      fetchAllCofrades: 'cofrade/fetchAllCofrades',
      fetchAllShares: 'share/fetchAllShares',
      setCurrentCofradeId: 'cofrade/setCurrentCofradeId',
      cleanCurrentCofradeId: 'cofrade/cleanCurrentCofradeId',
    }),
  },
  created() {
    if (!this.areCofradesLoaded) this.fetchAllCofrades();
    if (!this.areSharesLoaded) this.fetchAllShares();
  },
  destroyed() {
    this.cleanCurrentCofradeId();
  },
}
</script>