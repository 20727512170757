import AuthService from "@/services/auth.service";
import ApiRoutes from "@/api/ApiRoutes";
import axios from "axios";

export default class ShareService {
  static async getAll() {
    return (await axios.get(ApiRoutes.Shares.GetAll, {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getById(share_id) {
    return (await axios.get(ApiRoutes.Shares.GetById(share_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getOrdinary() {
    return (await axios.get(ApiRoutes.Shares.GetOrdinary, {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getOrdinaryDefault() {
    return (await axios.get(ApiRoutes.Shares.GetOrdinaryDefault, {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getEntry() {
    return (await axios.get(ApiRoutes.Shares.GetEntry, {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getEntryDefault() {
    return (await axios.get(ApiRoutes.Shares.GetEntryDefault, {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
}