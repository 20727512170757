import state from "./cofradeState";
import mutations from "./cofradeMutations";
import getters from "./cofradeGetters";
import actions from "./cofradeActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
