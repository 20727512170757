import Vue from 'vue';

export default {
  SET_BOOKS_MAP(state, booksMap) {
    state.books_map = booksMap;
    if (booksMap !== null)
      Vue.$toast.info("Libros cargados!");
  },
  SET_CURRENT_BOOK_COFRADES_MAP(state, bookCofradesMap) {
    state.current_book_cofrades_map = bookCofradesMap;
    if (bookCofradesMap !== null)
      Vue.$toast.info("Libro de cofrades cargado!");
  },
  SET_BOOK(state, book) {
    if (state.books_map) {
      Vue.set(state.books_map, book.book_id, book);
      Vue.$toast.info("Libro actualizado!");
    }
  },
  SET_CURRENT_BOOK_ID(state, book_id) {
    state.current_book_id = book_id;
  },
  ADD_BOOK(state, book) {
    if (state.books_map) {
      Vue.set(state.books_map, book.book_id, book);
      Vue.$toast.info("Nuevo libro cargado!");
    }
  },
  REMOVE_BOOK(state, book_id) {
    if (state.books_map && state.books_map[book_id]) {
      Vue.delete(state.books_map, book_id);
      Vue.$toast.info("Libro eliminado!");
    }
  },
};
