<template>
  <div class="container-fluid mt-2" id="Payments">
    <table v-if="arePaymentsLoaded" class="sticky-top-2 table table-sm table-hover">
      <thead class="bg-dark text-light">
        <tr>
          <th scope="col">Fecha</th>
          <th scope="col">Pagador</th>
          <th scope="col">Cuota</th>
          <th scope="col">Años</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="payment in cofradePayments" :key="payment.payment_id">
          <td>{{moment({ format: 'calendar', date: payment.created_at })}}</td>
          <td>{{getCofradeById(payment.payer_id).full_name}}</td>
          <td>{{getShareById(payment.share_id).name}}</td>
          <td>{{getShareById(payment.share_id).entry ? '-' : payment.years}}</td>
          <td v-if="isIssuer || isAdmin" class="fit hidden-fourth" role="group">
            <button v-on:click.stop.prevent="deletePayment(payment)" class="btn btn-sm btn-block btn-danger" :disabled="payment.deleting">
              <span v-if="payment.deleting" class="spinner-border align-middle" style="width: 11px; height: 11px;"></span>
              <font-awesome-icon v-else icon="trash" aria-hidden="true"/>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="text-center">
      <span class="spinner-border spinner-border-xl"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import momentMixin from '@/mixins/momentMixin';
import PaymentService from '@/services/payment.service';

export default {
  name: 'Payments',
  mixins: [momentMixin],
  data() {
    return {
      cofradePayments: null
    }
  },
  computed: {
    ...mapGetters({
      isIssuer: 'auth/isIssuer',
      isAdmin: 'auth/isAdmin',
      getCofradePayments: 'payment/getCofradePayments',
      getShareById: 'share/getShareById',
      getCofradeById: 'cofrade/getCofradeById',
      arePaymentsLoaded: 'payment/areCofradePaymentsLoaded',
    })
  },
  watch: {
    getCofradePayments: {
      deep: true,
      handler() {
        this.cofradePayments = JSON.parse(JSON.stringify(this.getCofradePayments));
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCofradePayments: 'payment/fetchCofradePayments',
      cleanPayments: 'payment/cleanPayments',
    }),
    async deletePayment(payment) {
      this.$set(payment, "deleting", true);
      await PaymentService.delete(payment.payment_id);
      this.$set(payment, "deleting", false);
    }
  },
  created() {
    if (!this.arePaymentsLoaded) this.fetchCofradePayments();
  },
  mounted() {
    this.cofradePayments = JSON.parse(JSON.stringify(this.getCofradePayments));
  },
  destroyed() {
    this.cleanPayments();
  },
}
</script>