import moment from "moment";

export default {
  methods: {
    moment: ({ format, date, inputFormat }) => {
      switch (format) {
        case "form-control":
          if (date === null) return null;
          else if (date)
            if (inputFormat)
              return moment(date, inputFormat).isValid()
                ? moment(date, inputFormat).format("YYYY-MM-DDTHH:mm")
                : null;
            else
              return moment(date).isValid()
                ? moment(date).format("YYYY-MM-DDTHH:mm")
                : null;
          else return moment().format("YYYY-MM-DDTHH:mm");
        case "calendar":
          if (date === null) return null;
          else if (date)
            if (inputFormat)
              return moment(date, inputFormat).isValid()
                ? moment(date, inputFormat).format("DD/MM/YYYY")
                : null;
            else
              return moment(date).isValid()
                ? moment(date).format("DD/MM/YYYY")
                : null;
          else return moment().format("DD/MM/YYYY");
        case "year":
          if (date === null) return null;
          else if (date)
            if (inputFormat)
              return moment(date, inputFormat).isValid()
                ? parseInt(moment(date, inputFormat).format("YYYY"))
                : null;
            else
              return moment(date).isValid()
                ? parseInt(moment(date).format("YYYY"))
                : null;
          else return parseInt(moment().format("YYYY"));
        default:
          if (date === null) return null;
          else if (date)
            if (inputFormat) return moment(date, inputFormat);
            else return moment(date);
          else return moment();
      }
    }
  },
};
