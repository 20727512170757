import AuthService from "@/services/auth.service";
import ApiRoutes from "@/api/ApiRoutes";
import axios from "axios";

export default class PaymentService {
  static async getAll() {
    return (await axios.get(ApiRoutes.Payments.GetAll, {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getById(payment_id) {
    return (await axios.get(ApiRoutes.Payments.GetById(payment_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getByYear(year) {
    return (await axios.get(ApiRoutes.Payments.GetByYear(year), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getByCofradeId(cofrade_id) {
    return (await axios.get(ApiRoutes.Cofrades.GetPaymentsById(cofrade_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async post(payment) {
    const parsedPaymedt = this.parse(payment);
    return (await axios.post(ApiRoutes.Payments.Root, parsedPaymedt,
      { headers: AuthService.getAuthHeader() }
    )).data;
  }
  static async delete(payment_id) {
    return (await axios.delete(ApiRoutes.Payments.GetById(payment_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static parse(payment) {
    let parsedPayment = {};
    Object.assign(parsedPayment, 
      payment.payment_id !== null && payment.payment_id !== undefined ? {payment_id: parseInt(payment.payment_id) } : null,
      payment.cofrade_id !== null && payment.cofrade_id !== undefined ? {cofrade_id: parseInt(payment.cofrade_id) } : null,
      payment.payer_id   !== null && payment.payer_id   !== undefined ? {payer_id:   parseInt(payment.payer_id  ) } : null,
      payment.created_at !== null && payment.created_at !== undefined ? {created_at: new Date(payment.created_at) } : null,
      payment.share_id   !== null && payment.share_id   !== undefined ? {share_id:   parseInt(payment.share_id  ) } : null,
      payment.years      !== null && payment.years      !== undefined ? {years:      parseInt(payment.years     ) } : null,
    );
    return parsedPayment;
  }
}