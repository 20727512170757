function generalize(str) {
  return str
    .replace(/á/g, "a")
    .replace(/é/g, "e")
    .replace(/í/g, "i")
    .replace(/ó/g, "o")
    .replace(/ú/g, "u")
    .replace(/ü/g, "u")
    .replace(/Á/g, "a")
    .replace(/É/g, "e")
    .replace(/Í/g, "i")
    .replace(/Ó/g, "o")
    .replace(/Ú/g, "u")
    .replace(/Ü/g, "u")
    .toLowerCase();
}
function compare(a, b) {
  if (generalize(a.full_name_switched) < generalize(b.full_name_switched))
    return -1;
  else if (generalize(a.full_name_switched) > generalize(b.full_name_switched))
    return 1;
  else return 0;
}

export default {
  areLoaded: (state) => {
    return state.cofrades_map ? true : false
  },
  areConjointsLoaded: (state) => {
    return state.current_cofrade_conjoints_map ? true : false
  },
  arePredictedConjointsLoaded: (state) => {
    return state.current_cofrade_predicted_conjoints_map ? true : false;
  },
  getAllCofrades: (state) => {
    return state.cofrades_map
      ? Object.values(state.cofrades_map).sort(compare)
      : []
  },
  getCurrentCofrade: (state) => {
    return state.cofrades_map
      ? state.cofrades_map[state.current_cofrade_id]
      : null
  },
  getConjoints: (state) => {
    return state.current_cofrade_conjoints_map
      ? Object.values(state.current_cofrade_conjoints_map).sort(compare)
      : []
  },
  isConjoint: (state) => (cofrade_id) => {
    return state.current_cofrade_conjoints_map
      ? Object.keys(state.current_cofrade_conjoints_map).map(Number).includes(cofrade_id)
      : false
  },
  getPredictedConjoints: (state) => {
    return state.current_cofrade_predicted_conjoints_map
      ? Object.values(state.current_cofrade_predicted_conjoints_map).sort(compare)
      : []
  },
  getCofradeById: (state) => (cofrade_id) => {
    return state.cofrades_map
      ? state.cofrades_map[cofrade_id]
      : null
  },
}