import state from "./paymentState";
import mutations from "./paymentMutations";
import getters from "./paymentGetters";
import actions from "./paymentActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
