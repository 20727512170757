import Vue from 'vue'

export default {
  SET_SHARES_MAP(state, sharesMap) {
    state.shares_map = sharesMap;
    if (sharesMap !== null)
      Vue.$toast.info("Cuotas cargadas!");
  },
  SET_SHARE(state, share) {
    if (state.shares_map) {
      Vue.set(state.shares_map, share.share_id, share);
      Vue.$toast.info("Cuota actualizada!");
    }
  },
  ADD_SHARE(state, share) {
    if (state.shares_map) {
      Vue.set(state.shares_map, share.share_id, share);
      Vue.$toast.info("Nueva cuota cargada!");
    }
  },
  REMOVE_SHARE(state, share_id) {
    if (state.shares_map && state.shares_map[share_id]) {
      Vue.delete(state.shares_map, share_id);
      Vue.$toast.info("Cuota eliminada!");
    }
  },
};
