<template>
  <div class="container-fluid" id="CofradeCard">
    <div class="card my-3">
      <div class="card-header">Pago único</div>
      <div v-if="isCofradeInitialized" class="card-body">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="form-row">
              <div class="form-group col-sm">
                <label for="inputFullName">Nombre y apellidos</label>
                <input :value="cofrade.full_name_switched + (cofrade.nickname ? ` (${cofrade.nickname})` : '')" type="text" class="form-control form-control-sm" id="inputFullName" placeholder="Nombre y apellidos" disabled>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-sm-4" v-if="payedTill(cofrade) != payingTill({...cofrade, payingYears})">Pagando</label>
              <label class="col-sm-4" v-else>Pagado</label>
              <div class="col-sm-8 form-row text-center">
                <div class="col-4">
                  <h4 class="font-weight-bold" :class="isYearCurrentOrAfterNow(payedTill(cofrade)) ? 'bg-success-light' : 'bg-warning-light'" style="margin:0">
                    {{payedTill(cofrade) || "Sin pagos"}}
                  </h4>
                </div>
                <div class="col-4" v-if="payedTill(cofrade) != payingTill({...cofrade, payingYears})">
                  <h5 class="font-weight-bold" style="margin:0; padding-top: 3px;">
                    <font-awesome-icon icon="arrow-right" aria-hidden="true"/>
                  </h5>
                </div>
                <div class="col-4" v-if="payedTill(cofrade) != payingTill({...cofrade, payingYears})">
                  <h4 class="font-weight-bold" :class="isYearCurrentOrAfterNow(payingTill({...cofrade, payingYears})) ? 'bg-success-light' : 'bg-warning-light'" style="margin:0">
                    {{payingTill({...cofrade, payingYears}) || "Sin pagos"}}
                  </h4>
                </div>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-sm-4">Cuota</label>
              <div class="col-sm-8">
                <select v-model="cofrade.share_id" class="custom-select custom-select-sm">
                  <option v-for="share in getOrdinaryShares" :value="share.share_id" :key="share.share_id">{{share.name}}</option>
                </select>
                <div v-if="cofrade.paysEntry" class="form-check mt-1">
                  <input v-model="cofrade.paysEntry" class="form-check-input" type="checkbox" id="inputPaysEntry" disabled>
                  <label class="form-check-label font-weight-bold text-accent" for="inputPaysEntry">Paga entrada ({{getDefaultEntryShare.price - getShareById(cofrade.share_id).price}}€)</label>
                </div>
              </div>
            </div>
            <div class="form-group form-row">
              <label for="inputPayingYears" class="col-sm-4">Años a pagar</label>
              <ValidationProvider class="col-sm-8" name="años a pagar" tag="div" rules="positive|required" v-slot="{ classes }">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span v-on:click="payingYears > 0 ? payingYears-- : payingYears = 0" class="btn btn-outline-secondary" >
                      <font-awesome-icon icon="minus" aria-hidden="true"/>
                    </span>
                  </div>
                  <input ref="inputPayingYears" v-model.number="payingYears" type="text" class="form-control pr-1" id="inputPayingYears" :class="classes">
                  <div class="input-group-append">
                    <span v-on:click.stop.prevent="payingYears++" class="btn btn-outline-secondary" type="button">
                      <font-awesome-icon icon="plus" aria-hidden="true"/>
                    </span>
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group form-row">
              <label class="col-sm-4">Total</label>
              <div class="col-sm-8">
                <b>{{totalCofrade({...cofrade, payingYears})}}<span class="pl-2 pr-2">€</span></b>
              </div>
            </div>
            <div v-if="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
            <div class="float-right">
              <button v-if="!isModified()" class="btn btn-success text-uppercase" v-on:click.stop.prevent>
                <span>Actual</span>
              </button>
              <button v-else class="btn btn-danger text-uppercase" :disabled="loading" type="submit">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span> Enviar</span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PaymentCalculator, TimeFormatter, shallowDiff } from '@/helpers';
import formValidationMixin from '@/mixins/formValidationMixin';
import momentMixin from '@/mixins/momentMixin';
import PaymentService from '@/services/payment.service';

export default {
  name: 'CofradeSinglePay',
  mixins: [formValidationMixin, momentMixin],
  data() {
    return {
      payingYears: 0,
      isCofradeInitialized: false,
      cofrade: null,
      loading: false,
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      getCurrentCofrade: 'cofrade/getCurrentCofrade',
      getOrdinaryShares: 'share/getOrdinaryShares',
      getDefaultOrdinaryShare: 'share/getDefaultOrdinaryShare',
      getDefaultEntryShare: 'share/getDefaultEntryShare',
      getShareById: 'share/getShareById',
    }),
  },
  watch: {
    getCurrentCofrade: {
      deep: true,
      async handler(newCofrade, oldCofrade) {
        Object.assign(this.cofrade, shallowDiff(newCofrade, oldCofrade));
        this.cofrade.paysEntry = !this.cofrade.payed_entry;
        let unpayedYears = ((new Date()).getFullYear() - this.payedTill(this.cofrade));
        this.payingYears = (this.cofrade.payed_entry)
          ? ((unpayedYears > 0) ? unpayedYears : 0)
          : ((unpayedYears > 0) ? unpayedYears+1 : 1);
      }
    },
  },
  methods: {
    payedTill: PaymentCalculator.payedTill,
    payingTill: PaymentCalculator.payingTill,
    totalCofrade: PaymentCalculator.totalCofrade,
    total: PaymentCalculator.total,
    isYearCurrentOrAfterNow: TimeFormatter.isYearCurrentOrAfterNow,
    async onSubmit() {
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      this.loading = true;
      let entryPayment = null;
      let complementaryPayment = null;
      if (this.cofrade.paysEntry) {
        entryPayment = {
          years: 1,
          share_id: this.getDefaultEntryShare.share_id,
          cofrade_id: this.cofrade.cofrade_id,
          payer_id: this.cofrade.cofrade_id,
        };
      }
      if (this.payingYears > 1*this.cofrade.paysEntry) {
        complementaryPayment = {
          years: this.payingYears - 1*this.cofrade.paysEntry,
          share_id: this.cofrade.share_id,
          cofrade_id: this.cofrade.cofrade_id,
          payer_id: this.cofrade.cofrade_id,
        }
      }
      if (entryPayment){
        await PaymentService.post(entryPayment)
          .then(() => {
            this.cofrade.share_id = this.getDefaultOrdinaryShare.share_id;
            this.payingYears = 0;
          })
          .catch(error => this.errorMessage = error.message);
      }
      if (complementaryPayment){
        await PaymentService.post(complementaryPayment)
          .then(() => {
            this.cofrade.share_id = this.getDefaultOrdinaryShare.share_id;
            let unpayedYears = ((new Date()).getFullYear() - this.payedTill(this.cofrade));
            this.payingYears = ((unpayedYears-complementaryPayment.years > 0) ? unpayedYears-complementaryPayment.years : 0);
          })
          .catch(error => this.errorMessage = error.message);
      }
      await sleep(1500);
      this.loading = false;
    },
    isModified() {
      return this.payingYears !== 0;
    },
  },
  mounted() {
    this.cofrade = JSON.parse(JSON.stringify(this.getCurrentCofrade));
    this.cofrade.share_id = this.getDefaultOrdinaryShare.share_id;
    this.cofrade.paysEntry = !this.cofrade.payed_entry;
    let unpayedYears = ((new Date()).getFullYear() - this.payedTill(this.cofrade));
    this.payingYears = (this.cofrade.payed_entry)
      ? ((unpayedYears > 0) ? unpayedYears : 0)
      : ((unpayedYears > 0) ? unpayedYears+1 : 1);
    this.isCofradeInitialized = true;
  },
}
</script>