import store from "@/store";
import BookService from "@/services/book.service";

const bookUpdate = {
  eventType: "BOOK_UPDATE",
  handle: function(event) {
    const book_id = JSON.parse(event.data).book_id;
    BookService.getById(book_id)
      .then((book) => store.commit("book/SET_BOOK", book.data))
      .catch(() => {});
  },
};

export default bookUpdate;
