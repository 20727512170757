<template>
  <div id="Home" class="pt-2">
    <WelcomeJumbotron/>
  </div>
</template>

<script>
import WelcomeJumbotron from '@/components/WelcomeJumbotron.vue'

export default {
  name: 'Home',
  components: {
    WelcomeJumbotron
  }
}
</script>
