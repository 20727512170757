import Vue from 'vue'

// Font Awesome used icons imports
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHome, faUserPlus, faSignInAlt, faSignOutAlt, faCheck, faUserShield, faSearch,
         faPlus, faMinus, faIndent, faBan, faCog, faTrash, faHistory, faChartLine, faMoneyBillAlt,
         faBookOpen, faFileAlt, faFileImport, faArrowUp, faArrowDown, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faHome, faUserPlus, faSignInAlt, faSignOutAlt, faCheck, faUserShield, faSearch,
            faPlus, faMinus, faIndent, faBan, faCog, faTrash, faHistory, faChartLine, faMoneyBillAlt,
            faBookOpen, faFileAlt, faFileImport, faArrowUp, faArrowDown, faArrowRight);
Vue.component("font-awesome-icon", FontAwesomeIcon);