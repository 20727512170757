import store from "@/store";

const shareDeletion = {
  eventType: "SHARE_DELETION",
  handle: function(event) {
    const share_id = JSON.parse(event.data).share_id;
    store.commit("share/REMOVE_SHARE", share_id);
  },
};

export default shareDeletion;
