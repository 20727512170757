import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: require("@/views/Home.vue").default,
  },
  {
    path: "/login",
    name: "login",
    component: require("@/views/Login.vue").default,
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user && store.state.auth.user.accessToken)
        return next("/cofrades");
      next();
    },
  },
  {
    path: "/recent",
    name: "recent",
    component: require("@/views/Recent.vue").default,
    beforeEnter: (to, from, next) => {
      if (!(store.state.auth.user && store.state.auth.user.accessToken))
        return next("/login");
      next();
    },
  },
  {
    path: "/cofrades",
    component: require("@/views/cofrade/Index.vue").default,
    children: [
      {
        path: "",
        name: "cofrades",
        component: require("@/views/cofrade/List.vue").default,
      },
      {
        path: ":cofrade_id",
        component: require("@/views/cofrade/Cofrade.vue").default,
        children: [
          {
            path: "profile",
            name: "cofrade-profile",
            component: require("@/views/cofrade/Profile.vue").default,
          },
          {
            path: "payments",
            name: "cofrade-payments",
            component: require("@/views/cofrade/Payments.vue").default,
          },
        ],
        beforeEnter: (to, from, next) => {
          store.dispatch("cofrade/setCurrentCofradeId", to.params.cofrade_id);
          next();
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      if (!(store.state.auth.user && store.state.auth.user.accessToken))
        return next("/login");
      next();
    },
  },
  {
    path: "/admin",
    component: require("@/views/admin/Index.vue").default,
    children: [
      {
        path: "",
        name: "admin-dashboard",
        component: require("@/views/admin/Dashboard.vue").default,
      },
      {
        path: "views",
        component: require("@/views/admin/Views.vue").default,
        children: [
          {
            path: "books",
            name: "admin-view-books",
            component: require("@/views/admin/views/Books.vue").default,
          },
        ],
      },
      {
        path: "actions",
        component: require("@/views/admin/Actions.vue").default,
        children: [
          {
            path: "restore-cofrade",
            name: "admin-action-cofrade-restore",
            component: require("@/views/admin/actions/RestoreCofrade.vue")
              .default,
          },
          {
            path: "modify-payments",
            name: "admin-action-payment-modify",
            component: require("@/views/admin/actions/ModifyPayments.vue")
              .default,
          },
          {
            path: "modify-pages",
            name: "admin-action-page-modify",
            component: require("@/views/admin/actions/ModifyPages.vue").default,
          },
          {
            path: "delete-cofrades",
            name: "admin-action-cofrade-delete",
            component: require("@/views/admin/actions/DeleteCofrades.vue")
              .default,
          },
        ],
      },
    ],
    beforeEnter: (to, from, next) => {
      if (store.state.auth?.user?.role === 'admin' && !!store.state.auth?.user?.accessToken)
        return next();
      next("/login");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;