import store from '@/store';
import CofradeService from "@/services/cofrade.service";

const cofradeCreation = {
  eventType: 'COFRADE_CREATION',
  handle: function (event) {
    const cofrade_id = JSON.parse(event.data).cofrade_id
    CofradeService.getById(cofrade_id)
      .then((cofrade) => store.commit("cofrade/ADD_COFRADE", cofrade.data))
      .catch(() => {});
  }
}

export default cofradeCreation;