import state from "./shareState";
import mutations from "./shareMutations";
import getters from "./shareGetters";
import actions from "./shareActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
