export default {
  areLoaded: (state) => {
    return state.books_map ? true : false;
  },
  isCofradesBookMapLoaded: (state) => {
    return state.current_book_cofrades_map ? true : false
  },
  getAllBooks: (state) => {
    return state.books_map
      ? Object.values(state.books_map)
      : [];
  },
  getCofradesBookMap: (state) => {
    return state.current_book_cofrades_map
      ? Object.entries(state.current_book_cofrades_map).map((entry) => {
          return {
            page_id: parseInt(entry[0]),
            page_number: Math.floor((parseInt(entry[0])+1)/2),
            page_turned: parseInt(entry[0])%2===0,
            cofrades: Object.values(entry[1])
          }
        })
      : []
  },
  getCurrentBook: (state) => {
    return state.books_map
      ? state.books_map[state.current_book_id]
      : null
  },
  getBookById: (state) => (book_id) => {
    return state.books_map
      ? state.books_map[book_id]
      : null;
  },
};