<template>
  <div id="Login" class="col-sm-9 col-md-7 col-lg-5 mx-auto">
    <div class="card card-signin my-5">
      <div class="card-body">
        <div class="card-title text-center">
          <h4>Inicio de sesión</h4>
        </div>
        <hr>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form class="form-signin" @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider name="nombre de usuario" class="form-label-group" tag="div" rules="required|alpha_dash" v-slot="{ classes, errors }">
              <input v-focus v-model="user.username" id="inputUsername" type="text" class="form-control" :class="classes" placeholder="Nombre de usuario">
              <label for="inputUsername">Nombre de usuario</label>
              <div class="alert text-danger font-weight-light">{{ errors[0] }}</div>
            </ValidationProvider>
            <ValidationProvider name="contraseña" class="form-label-group" tag="div" rules="required" v-slot="{ classes, errors }">
              <input v-model="user.password" id="inputPassword" type="password" class="form-control" :class="classes" placeholder="Contraseña">
              <label for="inputPassword">Contraseña</label>
              <div class="alert text-danger font-weight-light">{{ errors[0] }}</div>
            </ValidationProvider>
            <button class="btn btn-lg btn-primary btn-block text-uppercase" :disabled="loading" type="submit">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              <span> Entrar</span>
            </button>
            <hr>
            <div v-if="errorMessage" class="form-group">
              <div class="alert alert-danger" role="alert">{{errorMessage}}</div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import formValidationMixin from '@/mixins/formValidationMixin';

export default {
  name: 'Login',
  mixins: [formValidationMixin],
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
      loading: false,
      errorMessage: ''
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn'
    })
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    async onSubmit() {
      this.loading = true;
      await this.login(this.user)
        .then(() => this.$router.push('/cofrades'))
        .catch((error) => {
          this.errorMessage = error.message;
        })
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 var(--accent-border);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group>input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group>input,
.form-label-group>label {
  padding: 0.75rem;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group>div {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
}

/* Fallback for Edge */
@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }
}

/* Fallback for IE */
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
}
</style>