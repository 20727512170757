<template>
  <div id="RestoreCofrade" class="container-fluid pt-2">
    <div class="card mt-2">
      <div class="card-header">Información</div>
      <div class="card-body">
        <ValidationObserver v-if="areBooksLoaded && areSharesLoaded" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="form-row">
              <ValidationProvider class="form-group col-sm" name="nombre" tag="div" rules="alpha_spaces|required|max:100" v-slot="{ classes }">
                <label for="inputName">Nombre</label>
                <input v-model="cofrade.first_name" type="text" class="form-control form-control-sm" :class="classes" id="inputName" placeholder="Nombre">
              </ValidationProvider>
            </div>
            <div class="form-row">
              <ValidationProvider class="form-group col-sm" name="primer apellido" tag="div" rules="alpha_spaces|required|max:100" v-slot="{ classes }">
                <label for="inputLastName1">Primer apellido</label>
                <input v-model="cofrade.last_name_1" type="text" class="form-control form-control-sm" :class="classes" id="inputLastName1" placeholder="Primer apellido">
              </ValidationProvider>
              <ValidationProvider class="form-group col-sm" name="segundo apellido" tag="div" rules="alpha_spaces|max:100" v-slot="{ classes }">
                <label for="inputLastName2">Segundo apellido</label>
                <input v-model="cofrade.last_name_2" type="text" class="form-control form-control-sm" :class="classes" id="inputLastName2" placeholder="Segundo apellido">
              </ValidationProvider>
            </div>
            <div class="form-row">
              <ValidationProvider class="form-group col-sm" name="teléfono 1" tag="div" rules="alpha_dash|max:15" v-slot="{ classes }">
                <label for="inputPhone1">Teléfono 1</label>
                <input v-model="cofrade.phone_1" type="text" class="form-control form-control-sm" :class="classes" id="inputPhone1" placeholder="Teléfono 1">
              </ValidationProvider>
              <ValidationProvider class="form-group col-sm" name="teléfono 2" tag="div" rules="alpha_dash|max:15" v-slot="{ classes }">
                <label for="inputPhone2">Teléfono 2</label>
                <input v-model="cofrade.phone_2" type="text" class="form-control form-control-sm" :class="classes" id="inputPhone2" placeholder="Teléfono 2">
              </ValidationProvider>
            </div>
            <div class="form-row">
              <ValidationProvider class="form-group col-sm" name="año de ingreso" tag="div" rules="natural|min_value:1800|required" v-slot="{ classes }">
                <label for="inputEntryYear">Año de ingreso</label>
                <input v-model.number="cofrade.entryYear" type="number" class="form-control form-control-sm" :class="classes" id="inputEntryYear" placeholder="No registrado">
              </ValidationProvider>
              <ValidationProvider class="form-group col-sm" name="pagado hasta" tag="div" :rules="`natural|min_value:${cofrade.entryYear+1}|required`" v-slot="{ classes }">
                <label for="inputPayedTill">Pagado hasta</label>
                <input v-model.number="cofrade.payedTill" type="number" class="form-control form-control-sm" :class="classes" id="inputPayedTill" placeholder="Sin pagos">
              </ValidationProvider>
            </div>
            <div class="form-row">
              <div class="form-group col-sm">
                <label for="inputBook">Libro</label>
                <select id="inputBook" v-model="cofrade.book_id" class="custom-select custom-select-sm">
                  <option v-for="book in books" :value="book.book_id" :key="book.book_id">{{`#${book.book_id} - ${book.title}`}}</option>
                </select>
              </div>
              <ValidationProvider class="form-group col-sm" name="pagina" tag="div" rules="natural|required" v-slot="{ classes }">
                <label for="inputPage">Página</label>
                <input v-model.number="cofrade.parsed_page_number" type="number" class="form-control form-control-sm" :class="classes" id="inputPage" placeholder="Página">
              </ValidationProvider>
              <div class="form-check mb-3 ml-2 mr-2">
                <input v-model="cofrade.page_turned" class="form-check-input" type="checkbox" id="inputTurned">
                <label class="form-check-label" for="inputTurned">Vuelto</label>
              </div>
            </div>
            <div class="col card mb-3">
              <div class="row">
                <div class="col-sm-4 pl-1 pr-0">
                  <div class="form-check">
                    <input v-model="cofrade.deceased" class="form-check-input" type="checkbox" id="inputDeceased">
                    <label class="form-check-label" for="inputDeceased">Difunto</label>
                  </div>
                </div>
                <div class="col-sm-4 pl-1 pr-0">
                  <div class="form-check">
                    <input v-model="cofrade.presbyter" class="form-check-input" type="checkbox" id="inputPresbyter">
                    <label class="form-check-label" for="inputPresbyter">Presbítero</label>
                  </div>
                </div>
                <div class="col-sm-4 pl-1 pr-0">
                  <div class="form-check">
                    <input v-model="cofrade.authority" class="form-check-input" type="checkbox" id="inputAuthority">
                    <label class="form-check-label" for="inputAuthority">Autoridad</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group form-row">
              <label for="inputAddress1" class="col-sm-3 col-form-label">Dirección</label>
              <div class="col-sm-9">
                <ValidationProvider name="línea de dirección 1" tag="div" rules="max:255" v-slot="{ classes }">
                  <input v-model="cofrade.address_1" type="text" class="form-control form-control-sm" :class="classes" id="inputAddress1" placeholder="Línea de dirección 1">
                </ValidationProvider>
                <ValidationProvider name="línea de dirección 2" tag="div" rules="max:255" v-slot="{ classes }">
                  <input v-model="cofrade.address_2" type="text" class="form-control form-control-sm" :class="classes" id="inputAddress2" placeholder="Línea de dirección 2">
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group form-row">
              <label for="inputNotes" class="col-sm-3 col-form-label">Notas</label>
              <div class="col-sm-9">
                <textarea v-model="cofrade.notes" class="form-control form-control-sm" id="inputNotes" placeholder="Notas" rows="2"></textarea>
              </div>
            </div>
            <div v-if="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
            <div class="float-right">
              <button class="btn btn-danger text-uppercase" :disabled="loading" type="submit">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span> Enviar</span>
              </button>
            </div>
          </form>
        </ValidationObserver>
        <div v-else-if="!errorMessage" class="text-center">
          <span class="spinner-border spinner-border-xl"></span>
        </div>
        <div v-else class="container">
          <div class="mt-2 alert alert-danger">{{errorMessage}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import formValidationMixin from '@/mixins/formValidationMixin';
import momentMixin from '@/mixins/momentMixin';
import { PageParser } from '@/helpers';
import CofradeService from '@/services/cofrade.service';
import PaymentService from '@/services/payment.service';

export default {
  name: 'RestoreCofrade',
  mixins: [formValidationMixin, momentMixin],
  data () {
    return {
      cofrade: {
        payedTill: this.moment({ format: 'year' })
      },
      loading: false,
      errorMessage: '',
    }
  },
  computed: {
    ...mapGetters({
      books: 'book/getAllBooks',
      areBooksLoaded: 'book/areLoaded',
      ordinaryShares: 'share/getOrdinaryShares',
      defaultOrdinaryShare: 'share/getDefaultOrdinaryShare',
      defaultEntryShare: 'share/getDefaultEntryShare',
      areSharesLoaded: 'share/areLoaded',
    })
  },
  methods: {
    ...mapActions({
      fetchAllBooks: 'book/fetchAllBooks',
      fetchAllShares: 'share/fetchAllShares',
    }),
    encodePageNumber: PageParser.encodePageNumber,
    async onSubmit() {
      this.loading = true;
      this.cofrade.entry_date = this.moment({ format: '', date: `1607${this.cofrade.entryYear}`, inputFormat: 'DDMMYYYY' });
      await CofradeService.post({
        ...this.cofrade,
        page_number: this.encodePageNumber(this.cofrade.parsed_page_number, this.cofrade_page_turned)
      }).then(async (response) => {
        let entryPayment = {
          created_at: this.cofrade.entry_date,
          years: 1,
          share_id: this.defaultEntryShare.share_id,
          cofrade_id: response.data.cofrade_id,
          payer_id: response.data.cofrade_id
        }
        let ordinaryPayment = null;
        if (this.cofrade.payedTill > this.cofrade.entryYear) {
          ordinaryPayment = {
            created_at: this.cofrade.entry_date,
            years: this.cofrade.payedTill - this.cofrade.entryYear,
            share_id: this.defaultOrdinaryShare.share_id,
            cofrade_id: response.data.cofrade_id,
            payer_id: response.data.cofrade_id
          }
        }
        if (entryPayment) 
          await PaymentService.post(entryPayment).catch((error) => this.errorMessage = error.message);
        if (ordinaryPayment)
          await PaymentService.post(ordinaryPayment).catch((error) => this.errorMessage = error.message);
      }).catch((error) => {
        this.errorMessage = error.message
      });
      if (!this.errorMessage) {
        this.cofrade = {
          payedTill: this.moment({ format: 'year' })
        };
      }
      this.loading = false;
    }
  },
  created() {
    if (!this.areBooksLoaded) this.fetchAllBooks();
    if (!this.areSharesLoaded) this.fetchAllShares();
  },
}
</script>