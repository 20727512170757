<template>
  <div v-if="areBooksLoaded" id="Books">
    <div class="container-fluid pt-2 pb-2">
      <div class="card card-body pt-2 pb-2">
        <div class="form-group form-row m-0">
          <label for="inputBook" class="col-sm-2 m-0 p-1" style="font-size: 100%;">Libro:</label>
          <select id="inputBook" v-model="selectedBook" v-on:change="loadBook" class="col-sm-10 custom-select custom-select-sm">
            <option :value="null" disabled selected hidden>Selecciona un libro</option>
            <option v-for="book in books" :value="book" :key="book.book_id">{{`#${book.book_id} - ${book.title}`}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-2" v-if="isCofradesBookMapLoaded">
      <table class="table table-sm table-responsive-xs table-hover">
        <thead class="bg-dark text-light">
          <tr>
            <th scope="col">Página</th>
            <th scope="col">Cofrades</th>
          </tr>
        </thead>
        <tbody>
          <!-- Styling not working -->
          <tr v-for="page in cofradesBookMap" :key="page.page_id" :class="getPageColor(page.cofrades.length)">
            <td>{{`${page.page_number}${page.page_turned ? ' vto' : ''}`}}</td>
            <td>
              <span v-for="cofrade in page.cofrades" :key="cofrade.cofrade_id">{{cofrade.full_name_switched}}
                <br>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else class="mt-2 text-center">
    <span class="spinner-border spinner-border-xl"></span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Books',
  data() {
    return {
      errorMessage: null,
      selectedBook: null,
    }
  },
  computed: {
    ...mapGetters({
      books: 'book/getAllBooks',
      book: 'book/getCurrentBook',
      cofradesBookMap: 'book/getCofradesBookMap',
      areBooksLoaded: 'book/areLoaded',
      isCofradesBookMapLoaded: 'book/isCofradesBookMapLoaded',
    })
  },
  methods: {
    ...mapActions({
      fetchAllBooks: 'book/fetchAllBooks',
      fetchCurrentBookCofrades: 'book/fetchCurrentBookCofrades',
      setCurrentBookId: 'book/setCurrentBookId',
    }),
    loadBook() {
      this.setCurrentBookId(this.selectedBook.book_id);
      this.fetchCurrentBookCofrades();
    },
    getPageColor(numCofrades) {
      if (numCofrades > this.book.num_positions) {
        return 'table-danger';
      } else if (numCofrades < this.book.num_positions) {
        return 'table-warning';
      } else {
        return 'table-success';
      }
    }
  },
  created() {
    if (!this.areBooksLoaded) {
      this.fetchAllBooks();
    }
  },
}
</script>