import store from "@/store";
import ShareService from "@/services/share.service";

const shareUpdate = {
  eventType: "SHARE_UPDATE",
  handle: function(event) {
    const share_id = JSON.parse(event.data).share_id;
    ShareService.getById(share_id)
      .then((share) => store.commit("share/SET_SHARE", share.data))
      .catch(() => {});
  },
};

export default shareUpdate;
