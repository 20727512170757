import store from '@/store';
import PaymentService from "@/services/payment.service";

const paymentUpdate = {
  eventType: 'PAYMENT_UPDATE',
  handle: function (event) {
    const payment_id = JSON.parse(event.data).payment_id;
    PaymentService.getById(payment_id)
      .then((payment) => store.commit("payment/SET_PAYMENT", payment.data))
      .catch(() => {});
  }
}

export default paymentUpdate;


// TODO MISSING TO MODIFY SHARE AND USER