import ApiRoutes from '@/api/ApiRoutes'
import Handlers from './handlers'

export default function configureEventSources() {
  const eventSource = new EventSource(ApiRoutes.EventSource)
  for (const handler of Handlers) {
    eventSource.addEventListener(handler.eventType, event => {
      handler.handle(event)
    })
  }
}