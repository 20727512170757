<template>
  <div class="container-fluid" id="CofradeCard">
    <div class="card my-3">
      <div class="card-header">Información</div>
      <div v-if="isCofradeInitialized" class="card-body">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div v-if="cofrade.nickname" class="form-row">
              <ValidationProvider class="form-group col-sm-8" name="nombre" tag="div" rules="alpha_spaces|required|max:100" v-slot="{ classes }">
                <label for="inputName">Nombre</label>
                <input v-model="cofrade.first_name" type="text" class="form-control form-control-sm" :class="classes" id="inputName" placeholder="Nombre" :disabled="!(isIssuer || isAdmin)">
              </ValidationProvider>
              <ValidationProvider class="form-group col-sm-4" name="pseudónimo" tag="div" rules="alpha_spaces|max:100" v-slot="{ classes }">
                <label for="inputLastName2">Pseudónimo</label>
                <input v-model="cofrade.nickname" type="text" class="form-control form-control-sm" :class="classes" id="inputNickname" placeholder="Pseudónimo" :disabled="!(isIssuer || isAdmin)">
              </ValidationProvider>
            </div>
            <div v-else class="form-row">
              <ValidationProvider class="form-group col-sm" name="nombre" tag="div" rules="alpha_spaces|required|max:100" v-slot="{ classes }">
                <label for="inputName">Nombre</label>
                <input v-model="cofrade.first_name" type="text" class="form-control form-control-sm" :class="classes" id="inputName" placeholder="Nombre" :disabled="!(isIssuer || isAdmin)">
              </ValidationProvider>
            </div>
            <div class="form-row">
              <ValidationProvider class="form-group col-sm" name="primer apellido" tag="div" rules="alpha_spaces|required|max:100" v-slot="{ classes }">
                <label for="inputLastName1">Primer apellido</label>
                <input v-model="cofrade.last_name_1" type="text" class="form-control form-control-sm" :class="classes" id="inputLastName1" placeholder="Primer apellido" :disabled="!(isIssuer || isAdmin)">
              </ValidationProvider>
              <ValidationProvider class="form-group col-sm" name="segundo apellido" tag="div" rules="alpha_spaces|max:100" v-slot="{ classes }">
                <label for="inputLastName2">Segundo apellido</label>
                <input v-model="cofrade.last_name_2" type="text" class="form-control form-control-sm" :class="classes" id="inputLastName2" placeholder="Segundo apellido" :disabled="!(isIssuer || isAdmin)">
              </ValidationProvider>
            </div>
            <div class="form-row">
              <ValidationProvider class="form-group col-sm" name="teléfono 1" tag="div" rules="numeric|max:15" v-slot="{ classes }">
                <label for="inputPhone1">Teléfono 1</label>
                <input v-model="cofrade.phone_1" type="text" class="form-control form-control-sm" :class="classes" id="inputPhone1" placeholder="Teléfono 1" :disabled="!(isIssuer || isAdmin)">
              </ValidationProvider>
              <ValidationProvider class="form-group col-sm" name="teléfono 2" tag="div" rules="numeric|max:15" v-slot="{ classes }">
                <label for="inputPhone2">Teléfono 2</label>
                <input v-model="cofrade.phone_2" type="text" class="form-control form-control-sm" :class="classes" id="inputPhone2" placeholder="Teléfono 2" :disabled="!(isIssuer || isAdmin)">
              </ValidationProvider>
            </div>
            <div class="form-row">
              <div class="form-group col-sm">
                <label for="inputEntryDate">Fecha de ingreso</label>
                <input :value="moment({ format: 'calendar', date: cofrade.entry_date || null })" type="text" class="form-control form-control-sm" id="inputEntryDate" placeholder="No registrado" disabled>
              </div>
              <div class="form-group col-sm">
                <label for="inputPayedTill">Pagado hasta</label>
                <input :value="payedTill(cofrade)" type="number" class="form-control form-control-sm" id="inputPayedTill" placeholder="Sin pagos" disabled>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm">
                <label for="inputBook">Libro</label>
                <input :value="`#${cofrade.book_id} - ${getBookById(cofrade.book_id).title}`" type="text" class="form-control form-control-sm" id="inputBook" placeholder="-" disabled>
              </div>
              <div class="form-group col-sm">
                <label for="inputPage">Página</label>
                <input :value="parsePageNumber(cofrade.page_number)" type="text" class="form-control form-control-sm" id="inputPage" placeholder="-" disabled>
              </div>
            </div>
            <div class="col card mb-3">
              <div class="row">
                <div class="col-sm-4 pl-1 pr-0">
                  <div class="form-check">
                    <input v-model="cofrade.deceased" v-on:change="cofrade.modified=true" class="form-check-input" type="checkbox" id="inputDeceased" :disabled="!(isIssuer || isAdmin)">
                    <label class="form-check-label" for="inputDeceased">Difunto</label>
                  </div>
                </div>
                <div class="col-sm-4 pl-1 pr-0">
                  <div class="form-check">
                    <input v-model="cofrade.presbyter" v-on:change="cofrade.modified=true" class="form-check-input" type="checkbox" id="inputPresbyter" :disabled="!(isIssuer || isAdmin)">
                    <label class="form-check-label" for="inputPresbyter">Presbítero</label>
                  </div>
                </div>
                <div class="col-sm-4 pl-1 pr-0">
                  <div class="form-check">
                    <input v-model="cofrade.authority" v-on:change="cofrade.modified=true" class="form-check-input" type="checkbox" id="inputAuthority" :disabled="!(isIssuer || isAdmin)">
                    <label class="form-check-label" for="inputAuthority">Autoridad</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group form-row">
              <label for="inputAddress1" class="col-sm-3 col-form-label">Dirección</label>
              <div class="col-sm-9">
                <ValidationProvider name="línea de dirección 1" tag="div" rules="max:255" v-slot="{ classes }">
                  <input v-model="cofrade.address_1" type="text" class="form-control form-control-sm" :class="classes" id="inputAddress1" placeholder="Línea de dirección 1" :disabled="!(isIssuer || isAdmin)">
                </ValidationProvider>
                <ValidationProvider name="línea de dirección 2" tag="div" rules="max:255" v-slot="{ classes }">
                  <input v-model="cofrade.address_2" type="text" class="form-control form-control-sm" :class="classes" id="inputAddress2" placeholder="Línea de dirección 2" :disabled="!(isIssuer || isAdmin)">
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group form-row">
              <label for="inputNotes" class="col-sm-3 col-form-label">Notas</label>
              <div class="col-sm-9">
                <textarea v-model="cofrade.notes" class="form-control form-control-sm" id="inputNotes" placeholder="Notas" rows="2" :disabled="!(isIssuer || isAdmin)"></textarea>
              </div>
            </div>
            <div v-if="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
            <div v-if="isIssuer || isAdmin" class="float-right">
              <button v-if="!isModified()" class="btn btn-success text-uppercase" v-on:click.stop.prevent>
                <span>Actual</span>
              </button>
              <button v-else class="btn btn-danger text-uppercase" :disabled="loading" type="submit">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span> Enviar</span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div v-else class="text-center">
        <span class="spinner-border spinner-border-xl"></span>
      </div>
    </div>
  </div>
</template>

<script>

// TODO CREATE VIEW AT WIDE MEDIA QUERY

import { mapGetters } from 'vuex';
import { shallowDiff } from '@/helpers';
import formValidationMixin from '@/mixins/formValidationMixin';
import momentMixin from '@/mixins/momentMixin';
import { PaymentCalculator } from '@/helpers';
import { PageParser } from '@/helpers'
import CofradeService from '@/services/cofrade.service';

export default {
  name: 'CofradeCard',
  mixins: [formValidationMixin, momentMixin],
  data() {
    return {
      isCofradeInitialized: false,
      cofrade: null,
      loading: false,
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters({
      isIssuer: 'auth/isIssuer',
      isAdmin: 'auth/isAdmin',
      getCurrentCofrade: 'cofrade/getCurrentCofrade',
      getBookById: 'book/getBookById',
    })
  },
  watch: {
    getCurrentCofrade: {
      deep: true,
      async handler(newCofrade, oldCofrade) {
        Object.assign(this.cofrade, shallowDiff(newCofrade, oldCofrade));
      }
    }
  },
  methods: {
    payedTill: PaymentCalculator.payedTill,
    parsePageNumber: PageParser.parsePageNumber,
    async onSubmit() {
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      this.loading = true;
      if (this.isModified)
        await CofradeService.update({
          cofrade_id: this.cofrade.cofrade_id,
          ...shallowDiff(this.cofrade, this.getCurrentCofrade)
        }).catch((error) => this.errorMessage = error.message);
      await sleep(1500);
      this.loading = false;
    },
    isModified() {
      return Object.keys(shallowDiff(this.cofrade, this.getCurrentCofrade)).length !== 0 
    },
  },
  async mounted() {
    this.cofrade = await JSON.parse(JSON.stringify(this.getCurrentCofrade));
    this.isCofradeInitialized = true;
  },
}
</script>