export default {
  areLoaded: (state) => {
    return state.shares_map ? true : false
  },
  getAllShares: (state) => {
    return state.shares_map
      ? Object.values(state.shares_map)
      : []
  },
  getShareById: (state) => (share_id) => {
    return state.shares_map
      ? state.shares_map[share_id]
      : null
  },
  getEntryShares: (state) => {
    return state.shares_map
      ? Object.values(state.shares_map).filter(
          (share) => share.entry == true
        )
      : []
  },
  getDefaultEntryShare: (state) => {
    return state.shares_map
      ? Object.values(state.shares_map).filter(
          (share) => share.entry == true && share.default == true
        )[0] || null
      : null
  },
  getOrdinaryShares: (state) => {
    return state.shares_map
      ? Object.values(state.shares_map).filter(
          (share) => share.entry == false
        )
      : []
  },
  getDefaultOrdinaryShare: (state) => {
    return state.shares_map
      ? Object.values(state.shares_map).filter(
          (share) => share.entry == false && share.default == true
        )[0] || null
      : null
  },
};
