<template>
  <div class="container">
    <div id="WelcomeJumbotron" class="jumbotron">
      <h2 class="display-6">Aplicativa de Gestión de Cofrades de la Cofradía de la Virgen del Carmen de Lugo</h2>
      <p class="lead">Interfaz simple de comunicación con la API de gestión de cofrades en 
        <a href="http://cofradiacarmen.ddns.net/api">http://cofradiacarmen.ddns.net/api</a>
      </p>
      <hr class="my-4">
      <p>Documentación disponible en 
        <a href="">null</a>
      </p>
      <div v-if="!isLoggedIn" class="d-flex justify-content-end">
        <router-link to="/login" class="btn btn-secondary btn-lg">
            <font-awesome-icon icon="sign-in-alt" /> Entrar
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn'
    })
  },
};
</script>