import AuthService from "@/services/auth.service";
import ApiRoutes from "@/api/ApiRoutes";
import axios from "axios";

export default class BookService {
  static async getAll() {
    return (await axios.get(ApiRoutes.Books.GetAll, {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getById(book_id) {
    return (await axios.get(ApiRoutes.Books.GetById(book_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getCofradesById(book_id) {
    return (await axios.get(ApiRoutes.Books.GetCofradesById(book_id), {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
  static async getCurrent() {
    return (await axios.get(ApiRoutes.Books.GetCurrent, {
      headers: AuthService.getAuthHeader(),
    })).data;
  }
}