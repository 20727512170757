export default {
  isLoggedIn: (state) => {
    return !!(state.user && state.user.accessToken);
  },
  isUser: (state) => {
    return state.user?.accessToken && (state.user?.role === "user");
  },
  isIssuer: (state) => {
    return state.user?.accessToken && (state.user?.role === "issuer");
  },
  isAdmin: (state) => {
    return state.user?.accessToken && (state.user?.role === "admin");
  }
}