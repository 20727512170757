import store from '@/store';

export default class PaymentCalculator {
  static payedTill(cofrade, temp_cofrade) {
    let { entry_date, payed_entry, payed_years } = {...cofrade, ...temp_cofrade};
    if (payed_entry) {
      return (new Date(entry_date)).getFullYear() + payed_years
    } else {
      return 'Sin pagos';
    }
  }
  static payingTill(cofrade, temp_cofrade) {
    let { entry_date, payed_entry, payed_years, payingYears } = {...cofrade, ...temp_cofrade};
    if (payed_entry) {
      return (payingYears && payingYears > 0)
        ? (new Date(entry_date)).getFullYear() + payed_years + payingYears
        : (new Date(entry_date)).getFullYear() + payed_years;
    } else {
      return (payingYears && payingYears > 0)
        ? (new Date(entry_date)).getFullYear() + payed_years + payingYears - 1
        : 'Sin pagos';
    }
  }
  static totalCofrade(cofrade, temp_cofrade) {
    let { paysEntry, payingYears, share_id } = {...cofrade, ...temp_cofrade}
    if (paysEntry)
      return payingYears > 0
        ? parseFloat(store.getters['share/getShareById'](share_id).price) * (payingYears - 1) + parseFloat(store.getters['share/getDefaultEntryShare'].price)
        : 0;
    else 
      return payingYears > 0
        ? parseFloat(store.getters["share/getShareById"](share_id).price) * payingYears
        : 0;
  }
  static total(cofrades, temp_cofrades) {
    return cofrades.reduce((currentTotal, cofrade) => currentTotal + this.totalCofrade(cofrade, temp_cofrades && temp_cofrades[cofrade.cofrade_id]), 0);
  }
}