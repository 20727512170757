import store from "@/store";

const bookDeletion = {
  eventType: "BOOK_DELETION",
  handle: function(event) {
    const book_id = JSON.parse(event.data).book_id;
    store.commit("book/REMOVE_BOOK", book_id);
  },
};

export default bookDeletion;