import AuthService from '@/services/auth.service'

export default {
  login({ dispatch, commit }, user) {
    return new Promise((resolve, reject) => {
      AuthService.login(user.username, user.password)
        .then(async (user) => {
          commit("SET_USER", {
            accessToken: user.session.token,
            ...user.data
          });
          await dispatch("cofrade/fetchAllCofrades", null, { root: true });
          await dispatch("share/fetchAllShares", null, { root: true });
          await dispatch("book/fetchAllBooks", null, { root: true });
          resolve("success");
        })
        .catch((error) => {
          commit("SET_USER", null);
          reject(error);
        });
    });
  },
  logout({ commit }) {
    commit('SET_USER', null);
  }
}