import store from '@/store';

const cofradeDeletion = {
  eventType: 'COFRADE_DELETION',
  handle: function (event) {
    const cofrade_id = JSON.parse(event.data).cofrade_id
    store.commit('cofrade/REMOVE_COFRADE', cofrade_id)
  }
}

export default cofradeDeletion;