<template>
  <div class="container-fluid" style="margin-bottom:400px" id="CofradeMultiPay">
    <div class="card my-3">
      <div class="card-header">Multipago</div>
      <div class="card-body">
        <ValidationObserver v-if="conjoints.length" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <table class="table table-sm table-hover mb-0">
              <thead class="bg-dark text-light">
                <tr>
                  <th scope="col" class="hidden-fourth"></th>
                  <th scope="col">Nombre</th>
                  <th scope="col" class="hidden-third">Difunto</th>
                  <th scope="col" class="hidden-second">Cuota</th>
                  <th scope="col">Años</th>
                  <th scope="col">Pagando</th>
                  <th scope="col" class="hidden-first">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="conjoint in conjoints" :key="conjoint.cofrade_id">
                  <td class="fit hidden-fourth" style="text-align: center;">
                    <button v-if="getCurrentCofrade.cofrade_id !== conjoint.cofrade_id" v-on:click.stop.prevent="removeConjoint(conjoint)" class="btn btn-sm btn-danger">
                      <font-awesome-icon icon="minus" aria-hidden="true"/>
                    </button>
                    <font-awesome-icon v-else icon="user-shield" aria-hidden="true"/>
                  </td>
                  <td style="min-width:100px">
                    <div style="float:left;">
                      <div>
                        {{conjoint.full_name_switched + (conjoint.nickname ? ` (${conjoint.nickname})` : '')}}
                      </div>
                      <div>
                        <span class="shown-third" style="font-style: italic; font-size: 85%; float:left">
                          <hr style="margin:0">
                          &nbsp;&nbsp;Difunto&nbsp;
                        </span>
                        <input class="shown-third" type="checkbox" v-model="temp_conjoints[conjoint.cofrade_id].deceased">
                      </div>
                    </div>
                    <router-link tag="span" v-if="getCurrentCofrade.cofrade_id !== conjoint.cofrade_id" class="hidden-third" style="float:right; margin-right:1rem; cursor: pointer;" :to="{name: 'cofrade-profile', params: { cofrade_id: conjoint.cofrade_id }}">
                      <font-awesome-icon icon="search" aria-hidden="true"/>
                    </router-link>
                  </td>
                  <td class="hidden-third fit"><input type="checkbox" v-model="temp_conjoints[conjoint.cofrade_id].deceased"></td>
                  <td class="hidden-second" style="max-width:130px">
                    <select v-model="temp_conjoints[conjoint.cofrade_id].share_id" class="custom-select custom-select-sm">
                      <option v-for="share in getOrdinaryShares" :value="share.share_id" :key="share.share_id">{{share.name}}</option>
                    </select>
                    <div v-if="temp_conjoints[conjoint.cofrade_id].paysEntry" class="form-check mt-1">
                      <input v-model="temp_conjoints[conjoint.cofrade_id].paysEntry" class="form-check-input" type="checkbox" :id="`inputPaysEntry-${conjoint.cofrade_id}`" disabled>
                      <label class="form-check-label font-weight-bold text-accent" :for="`inputPaysEntry-${conjoint.cofrade_id}`">Paga entrada ({{getDefaultEntryShare.price - getShareById(temp_conjoints[conjoint.cofrade_id].share_id).price}}€)</label>
                    </div>
                  </td>
                  <td style="max-width:100px; min-width:80px">
                    <ValidationProvider name="años a pagar" tag="div" rules="natural|required" v-slot="{ classes }">
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend hidden-third">
                          <span v-on:click="temp_conjoints[conjoint.cofrade_id].payingYears > 0 ? temp_conjoints[conjoint.cofrade_id].payingYears-- : temp_conjoints[conjoint.cofrade_id].payingYears = 0" class="btn btn-outline-secondary" >
                            <font-awesome-icon icon="minus" aria-hidden="true"/>
                          </span>
                        </div>
                        <input v-model.number="temp_conjoints[conjoint.cofrade_id].payingYears" type="text" class="form-control " :class="classes">
                        <div class="input-group-append">
                          <span v-on:click.stop.prevent="temp_conjoints[conjoint.cofrade_id].payingYears++" class="btn btn-outline-secondary" type="button">
                            <font-awesome-icon icon="plus" aria-hidden="true"/>
                          </span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </td>
                  <td style="width: 120px;" :class="isYearCurrentOrAfterNow(payingTill(conjoint, temp_conjoints[conjoint.cofrade_id])) ? 'bg-success-light' : 'bg-warning-light'">
                    <div v-if="!temp_conjoints[conjoint.cofrade_id].loading" class="form-row text-center">
                      <div class="col-5 p-0">
                        <span class="font-weight-bold" :class="isYearCurrentOrAfterNow(payedTill(conjoint)) ? 'bg-success-light' : 'bg-warning-light'" style="margin:0">
                          {{payedTill(conjoint) || "-"}}
                        </span>
                      </div>
                      <div class="col-2 p-0" v-if="payedTill(conjoint) != payingTill(conjoint, temp_conjoints[conjoint.cofrade_id])">
                        <span class="font-weight-bold" style="margin:0; padding-top: 3px;">
                          <font-awesome-icon icon="arrow-right" aria-hidden="true"/>
                        </span>
                      </div>
                      <div class="col-5 p-0" v-if="payedTill(conjoint) != payingTill(conjoint, temp_conjoints[conjoint.cofrade_id])">
                        <span class="font-weight-bold" style="margin:0">
                          {{payingTill(conjoint, temp_conjoints[conjoint.cofrade_id]) || "-"}}
                        </span>
                      </div>
                    </div>
                    <div v-else class="text-center">
                      <span class="spinner-border spinner-border-sm"></span>
                    </div>
                  </td>

                  <td class="hidden-first" :class="isYearCurrentOrAfterNow(payingTill(conjoint, temp_conjoints[conjoint.cofrade_id])) ? 'bg-success-light' : 'bg-warning-light'">{{totalCofrade(conjoint, temp_conjoints[conjoint.cofrade_id])}}<span class="float-right pr-2">€</span></td>
                </tr>
                <tr>
                  <td>
                  </td>
                  <td class="hidden-first"></td>
                  <td class="hidden-second"></td>
                  <td class="hidden-third"></td>
                  <td class="hidden-fourth"></td>
                  <td :class="total(conjoints, temp_conjoints) > 0 ? 'table-danger' : ''">
                    <div class="btn-block">
                      <button v-if="!isModified()" class="btn btn-success text-uppercase" v-on:click.stop.prevent>
                        <span>Actual</span>
                      </button>
                      <button v-else class="btn btn-danger text-uppercase" :disabled="loading" type="submit">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span> Enviar</span>
                      </button>
                    </div>
                  </td>
                  <td :class="total(conjoints, temp_conjoints) > 0 ? 'table-danger' : ''">{{total(conjoints, temp_conjoints)}}<span class="float-right pr-2">€</span></td>
                </tr>
              </tbody>
            </table>
            <div v-if="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
          </form>
        </ValidationObserver>
        <hr>
        <CofradeMultiPayEmbeddedList/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PaymentCalculator, TimeFormatter, shallowDiff } from '@/helpers';
import formValidationMixin from '@/mixins/formValidationMixin';
import CofradeService from '@/services/cofrade.service';
import PaymentService from '@/services/payment.service';
import CofradeMultiPayEmbeddedList from '@/components/cofrade/CofradeMultiPayEmbeddedList';

export default {
  name: 'CofradeMultiPay',
  mixins: [formValidationMixin],
  components: {
    CofradeMultiPayEmbeddedList,
  },
  data() {
    return {
      loading: false,
      temp_conjoints: {},
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCofrade: 'cofrade/getCurrentCofrade',
      getCofradeById: 'cofrade/getCofradeById',
      areConjointsLoaded: 'cofrade/areConjointsLoaded',
      conjoints: 'cofrade/getConjoints',
      getOrdinaryShares: 'share/getOrdinaryShares',
      getDefaultOrdinaryShare: 'share/getDefaultOrdinaryShare',
      getDefaultEntryShare: 'share/getDefaultEntryShare',
      getShareById: 'share/getShareById',
      
    }),
  },
  watch: {
    conjoints: {
      handler(newConjointsArr, oldConjointsArr) {
        for (let conjoint of newConjointsArr) {
          if (this.temp_conjoints[conjoint.cofrade_id]) {
            let {payed_entry, deceased} = shallowDiff(conjoint, oldConjointsArr.filter(c => c.cofrade_id === conjoint.cofrade_id)[0]); 
            payed_entry !== undefined && this.$set(this.temp_conjoints[conjoint.cofrade_id], 'paysEntry', !payed_entry);
            deceased !== undefined && this.$set(this.temp_conjoints[conjoint.cofrade_id], 'deceased', deceased);
          } else {
            this.$set(this.temp_conjoints, conjoint.cofrade_id, {
              share_id: this.getDefaultOrdinaryShare.share_id,
              paysEntry: !conjoint.payed_entry,
              payingYears: 0,
              deceased: conjoint.deceased,
              loading: false,
            });
          }
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchCurrentCofradeConjoints: 'cofrade/fetchCurrentCofradeConjoints',
      cleanCurrentCofradeConjointsMap: 'cofrade/cleanCurrentCofradeConjointsMap',
      appendConjoint: 'cofrade/appendConjoint',
      removeConjoint: 'cofrade/removeConjoint',
    }),
    payedTill: PaymentCalculator.payedTill,
    payingTill: PaymentCalculator.payingTill,
    totalCofrade: PaymentCalculator.totalCofrade,
    total: PaymentCalculator.total,
    isYearCurrentOrAfterNow: TimeFormatter.isYearCurrentOrAfterNow,
    async onSubmit() {
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      this.loading = true;
      this.conjoints.forEach(async (base_conjoint) => {
        this.$set(this.temp_conjoints[base_conjoint.cofrade_id], 'loading', true);
        if (this.temp_conjoints && this.temp_conjoints[base_conjoint.cofrade_id]) {
          let conjoint = {...base_conjoint, ...this.temp_conjoints[base_conjoint.cofrade_id]};
          let entryPayment = (conjoint.paysEntry && conjoint.payingYears > 0)
            ? {
              years: 1,
              share_id: this.getDefaultEntryShare.share_id,
              cofrade_id: conjoint.cofrade_id,
              payer_id: this.getCurrentCofrade.cofrade_id,
            } : null;
          let complementaryPayment = (conjoint.payingYears > 1*conjoint.paysEntry)
            ? {
              years: conjoint.payingYears - 1*conjoint.paysEntry,
              share_id: conjoint.share_id,
              cofrade_id: conjoint.cofrade_id,
              payer_id: this.getCurrentCofrade.cofrade_id,
            } : null
          if (this.isDeceasedModified(conjoint)) {
            await CofradeService.update({
              cofrade_id: conjoint.cofrade_id,
              deceased: conjoint.deceased
            }).catch(error => this.errorMessage = error);
          }
          if (entryPayment){
            await PaymentService.post(entryPayment)
              .then(() => {
                this.$set(this.temp_conjoints[conjoint.cofrade_id], 'share_id', this.getDefaultOrdinaryShare.share_id);
                this.$set(this.temp_conjoints[conjoint.cofrade_id], 'payingYears', 0);
              })
              .catch(error => this.errorMessage = error.message);
          }
          if (complementaryPayment){
            await PaymentService.post(complementaryPayment)
              .then(() => {
                this.$set(this.temp_conjoints[conjoint.cofrade_id], 'share_id', this.getDefaultOrdinaryShare.share_id);
                this.$set(this.temp_conjoints[conjoint.cofrade_id], 'payingYears', 0);
              })
              .catch(error => this.errorMessage = error.message);
          }
        } else {
          this.errorMessage = 'Cofrade sin inicializar';
        }
        this.$set(this.temp_conjoints[base_conjoint.cofrade_id], 'loading', false);
      })
      await sleep(1500);
      this.loading = false;
    },
    isDeceasedModified(conjoint) {
      return conjoint.deceased != this.getCofradeById(conjoint.cofrade_id).deceased;
    },
    isAnyDeceasedModified() {
      return this.conjoints.some((conjoint) => this.isDeceasedModified({...conjoint, ...this.temp_conjoints[conjoint.cofrade_id]}));
    },
    isModified() {
      return this.isAnyDeceasedModified() || this.total(this.conjoints, this.temp_conjoints) !== 0
    }
  },
  created() {
    if (!this.areConjointsLoaded) this.fetchCurrentCofradeConjoints();
  },
  destroyed() {
    this.cleanCurrentCofradeConjointsMap();
  }
}
</script>