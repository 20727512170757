<template>
  <div id="Profile">
    <div v-if="(isIssuer || isAdmin)" class="container-fluid">
      <div class="row">
        <div class="col-md my-col" style="padding: 0px !important;">
          <CofradeCard v-if="areBooksLoaded" ref="cofradeCard"/>
        </div>
        <div class="col-md my-col" style="padding: 0px !important;">
          <CofradeSinglePay ref="cofradeSinglePay"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md my-col" style="padding: 0px !important;">
          <CofradeMultiPay ref="cofradeMultiPay"/>
        </div>
      </div>
    </div>
    <div v-else class="container-fluid">
      <div class="row">
        <CofradeCard v-if="areBooksLoaded"/>
      </div>
    </div>
    <Modal v-if="showModal" @close="closeModal()" @discard="discardChanges()">
      <h4 slot="header">Pagos sin enviar</h4>
      <span slot="body">¿Está seguro de que desea salir?<br>Los pagos pendientes de enviar no se guardarán.</span>
      <span slot="btn-discard">Salir</span>
      <span slot="btn-close">Volver</span>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CofradeCard from '@/components/cofrade/CofradeCard';
import CofradeSinglePay from '@/components/cofrade/CofradeSinglePay';
import CofradeMultiPay from '@/components/cofrade/CofradeMultiPay';
import Modal from '@/components/Modal';

export default {
  name: 'Profile',
  components: {
    CofradeCard,
    CofradeSinglePay,
    CofradeMultiPay,
    Modal
  },
  data() {
    return {
      to: null,
      showModal: false,
    }
  },
  computed: {
    ...mapGetters({
      isIssuer: 'auth/isIssuer',
      isAdmin: 'auth/isAdmin',
      areBooksLoaded: 'book/areLoaded',
    })
  },
  methods: {
    ...mapActions({
      fetchAllBooks: 'book/fetchAllBooks',
    }),
    closeModal() {
      this.showModal = false;
      this.to = null;
    },
    discardChanges() {
      this.showModal = false;
      this.$router.push(this.to);
    }
  },
  beforeRouteLeave (to, from, next) {
    if (
      !this.$refs.cofradeCard.isModified() &&
      !this.$refs.cofradeSinglePay.isModified() &&
      !this.$refs.cofradeMultiPay.isModified()
    ) {
      this.to = to;
    }
    if (this.to) {
      next();
    } else {
      this.to = to;
      this.showModal = true;
      next(false);
    }
  },
  created() {
    if (!this.areBooksLoaded) this.fetchAllBooks();
  },
}
</script>