import ShareService from "@/services/share.service";

export default {
  fetchAllShares({ commit }) {
    return new Promise((resolve, reject) => {
      ShareService.getAll()
        .then((shares) => {
          commit("SET_SHARES_MAP", shares.data)
          resolve('success');
        })
        .catch((error) => {
          reject(error.response?.data?.status);
        })
    });
  },
};
